import Navbar from "../../../components/navbar/navbar";
import { Container } from "./style";
import { useState } from "react";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import ProfileSidenav, { ProfileSidenavPage } from "../../../components/profile-sidenav/profileSidenav";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { useParams } from "react-router-dom";
import MySpaceEngineerProfile from "./my-space-engineer-profile";
import MySpaceEngineerBank from "./my-space-engineer-bank";
import PropertiesEngineer from "../../propertiesEngineer/propertiesEngineer";

export default function MySpaceEngineer() {
  const { subpage } = useParams();
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(subpage || "profile");

  return (
      <Container>
          <Navbar />
          <Breadcrumb itens={[t("my-space"), t(activePage)]} />

          <ProfileSidenav
              initialPage={subpage || "profile"}
              onChangePage={setActivePage}
              hidden={{ portfolio: true, bank: true }}
          >
              <ProfileSidenavPage pageId="profile">
                  <MySpaceEngineerProfile />
              </ProfileSidenavPage>
              <ProfileSidenavPage pageId="properties">
                  <PropertiesEngineer />
              </ProfileSidenavPage>
          </ProfileSidenav>

          <Footer />
      </Container>
  );
}
