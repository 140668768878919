import {
    Body,
    Container,
    ContainerText,
    ContainerImg,
    ButtonView,
    Title,
    Description,
    Img,
} from "./style";
import { storageServerPath } from "../../utils/server/api";
import { useTranslation } from "react-i18next";

const Partners = ({ data }: { data: any }) => {
    const { t } = useTranslation();
    const lng = localStorage.getItem("language");

    return (
        <>
            {(data[0] || data[1]) && (
                <Body>
                    {data[0] && (
                        <Container>
                            <ContainerText>
                                <Title>
                                    {lng === "en"
                                        ? data[0]?.company_name_en
                                        : data[0]?.company_name}
                                </Title>
                                <Description>
                                    {lng === "en"
                                        ? data[0]?.description_en
                                        : data[0]?.description}
                                </Description>
                                <ButtonView>
                                    <a
                                        href={data[0]?.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t("view-more")}
                                    </a>
                                </ButtonView>
                            </ContainerText>
                            <ContainerImg>
                                <Img
                                    src={`${storageServerPath}${data[0]?.image}`}
                                    alt={
                                        lng === "en"
                                            ? data[0]?.company_name_en
                                            : data[0]?.company_name
                                    }
                                    width={"100%"}
                                ></Img>
                            </ContainerImg>
                        </Container>
                    )}

                    {data[1] && (
                        <Container>
                            <ContainerText>
                                <Title>
                                    {lng === "en"
                                        ? data[1]?.company_name_en
                                        : data[1]?.company_name}
                                </Title>
                                <Description>
                                    {lng === "en"
                                        ? data[1]?.description_en
                                        : data[1]?.description}
                                </Description>
                                <ButtonView>
                                    <a
                                        href={data[1]?.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t("view-more")}
                                    </a>
                                </ButtonView>
                            </ContainerText>
                            <ContainerImg>
                                <Img
                                    src={`${storageServerPath}${data[1]?.image}`}
                                    alt={
                                        lng === "en"
                                            ? data[1]?.company_name_en
                                            : data[1]?.company_name
                                    }
                                    width={"100%"}
                                ></Img>
                            </ContainerImg>
                        </Container>
                    )}
                </Body>
            )}
        </>
    );
};

export default Partners;
