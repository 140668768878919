import styled from "styled-components";
import colors from "../../utils/colors";

export const FileContainer = styled.div`
    cursor: pointer;
    background-color: ${colors.primary};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 200px;
    min-height: 150px;
    padding: 10px;
    overflow: hidden;

    & > p {
        text-align: center;
    }

    & > img {
        width: auto;
        height: 40px;
    }
`;

export const FileLabel = styled.label`
    color: ${colors.secondary};
`;

export const FileNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const RemoveIcon = styled.span`
    width: 16px;
    height: 16px;
    cursor: pointer;
    font-weight: bold;
`;