import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  display: flex;
  width: 98%;
  height: 100px;
  display: flex;
  margin: 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 40px;
`;

export const Content = styled.div`
  width: 20%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
`;

export const ContentTwo = styled.div`
  width: 22%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const Text = styled.h1`
  font-family: "poppins";
  color: ${colors.text};
  font-size: 18px;
  margin-top: 5px;
`;
export const TextSlogan= styled.h1`
  font-family: "poppins";
  color: ${colors.text};
  font-size: 24px;
  margin-left: 15px;
  margin-top: 5px;
`;
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center; 
  padding-left: 30px;
`;

export const VerticalLine = styled.div`
  height: 100%; /* Altura da linha em relação ao container pai */
  border-left: 2px solid #45B7FE; 
`;
