import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    gap: 20px;
    cursor: pointer;
    font-size: 20px;
    font-family: "poppins";
    color: #50700F;
    margin-left: 2%;
`;

export const ButtonRemove = styled.div`
    display: flex;
    gap: 15px;
`;

export const DivRemove = styled.div`
    display: flex;
`;