import {
  Container,
  ContentImg,
  ContentLogin,
  Logo,
  ButtonLogin,
  ButtonPerson,
  ButtonSatellite

} from "./style";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/new-logo.png";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { useAuth } from "../../context/AuthContext";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";

export default function Choice() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { login, logout } = useAuth();

  useEffect(() => {
    logout();
  }, [])

  const handleLogin = (personal: string) => {
    localStorage.setItem("userType", personal);
    if (personal === "buy") {
      navigate("/loginBuy");
    } else if (personal === "enginner") {
      navigate("/loginengineer");
    } else if (personal === "seller") {
      navigate("/loginSeller");
    } else {

      const initialValues = {
          email: "satelite@satelite.com",
          password: "satelite",
          user_type: "satelite",
      };

      login(initialValues);
      
      navigate("/homeBuy");
      
    }
  };

  return (
      <>
          <NavBar />
          <Container>
              <ContentImg>
                  <Logo>
                      <img src={logo} alt="Ecolife"></img>
                  </Logo>
              </ContentImg>
              <ContentLogin>
                  <ButtonLogin>
                      <ButtonPerson onClick={() => handleLogin("seller")}>
                          <p>{t("seller")}</p>
                          {t("comment-seller")}
                      </ButtonPerson>
                      <ButtonPerson onClick={() => handleLogin("buy")}>
                          <p>{t("buyer")}</p>
                          {t("comment-buyer")}
                      </ButtonPerson>
                      <ButtonPerson onClick={() => handleLogin("enginner")}>
                          <p>{t("engineer")}</p>
                          {t("comment-engineer")}
                      </ButtonPerson>
                  </ButtonLogin>
                  <ButtonSatellite onClick={() => handleLogin("satelite")}>
                      <p>{t("satelite")}</p>
                  </ButtonSatellite>
              </ContentLogin>
              <ToastContainer />
          </Container>
          {/* <Footer/> */}
      </>
  );
}
