// PixModal.tsx
import React, { useState, useRef, useEffect } from "react";
import QRCode from "qrcode.react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface PixModalProps {
  onClose: () => void;
  pixCode?: string;
  ticket?: string;
  paymentMethod?: string;
}

const Title = styled.h1`
  font-family: "Josefin Bold";
  font-size: 12px;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-family: "Josefin Bold";
  font-size: 12px;
  margin-bottom: 20px;
  color: gray;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const QRCodeWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CodeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const CodeInput = styled.input`
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
`;

const CopyButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
`;

const ButtonTicket = styled.button`
  background-color: green;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 16px;

  &:hover {
    background-color: darkgreen; // Adicione um estilo de hover para destacar quando o usuário passa o mouse
  }
`;

const PixModal: React.FC<PixModalProps> = ({
  onClose,
  pixCode,
  ticket,
  paymentMethod,
}) => {
  const codeInputRef = useRef<HTMLInputElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const downloadBoleto = () => {
    const boletoLink = ticket || "";
    const link = document.createElement("a");

    link.href = boletoLink;
    link.download = "boleto.pdf";
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  useEffect(() => {
    setIsCopied(false);
  }, [pixCode]);

  const copyCodeToClipboard = () => {
    if (codeInputRef.current) {
      codeInputRef.current.select();
      document.execCommand("copy");
      setIsCopied(true);
    }
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <Title>{t("sucess-pay")}</Title>

        {paymentMethod === "2" && (
          <>
            {pixCode && (
              <>
                <Subtitle>{t("pay-warning")}</Subtitle>
                <QRCodeWrapper>
                  <QRCode value={pixCode} />
                </QRCodeWrapper>
                <CodeInputWrapper>
                  <CodeInput
                    ref={codeInputRef}
                    type="text"
                    value={pixCode}
                    readOnly
                  />
                  <CopyButton onClick={copyCodeToClipboard}>
                    {t("copy-code")}
                  </CopyButton>
                </CodeInputWrapper>
              </>
            )}
            {isCopied && <div>Código copiado com sucesso!</div>}
          </>
        )}

        {paymentMethod === "3" && (
          <>
            {ticket && (
              <>
                <Subtitle>{t("ticket-pay")}</Subtitle>
                <CodeInputWrapper>
                  <CodeInput
                    ref={codeInputRef}
                    type="text"
                    value={ticket}
                    readOnly
                  />
                  <CopyButton onClick={downloadBoleto}>
                    {t("download-boleto")}
                  </CopyButton>
                </CodeInputWrapper>
              </>
            )}
          </>
        )}

        <CloseButton onClick={onClose}>{t("payment-completed")}</CloseButton>
      </ModalContent>
    </ModalWrapper>
  );
};

export default PixModal;
