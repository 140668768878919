import React, { ReactNode } from "react";
import InputMask from "react-input-mask";
import { Icon, InputContainer, InputFieldMask } from "./style";

interface MaskedInputProps {
  field?: {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  mask: string;
  placeholder?: string;
  icon: ReactNode;
  iconSize?: string;
}

const MaskedInput: React.FC<MaskedInputProps> = ({
  field,
  mask,
  placeholder,
  icon,
}) => (
  <InputContainer>
    {icon && (
      <></>
    )}
    <InputFieldMask
      {...field}
      mask={mask}
      maskChar={null}
      placeholder={placeholder}
    />
  </InputContainer>
);

export default MaskedInput;
