import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    CardContainer,
    ButtonRemove,
    DivRemove,
    Quantity,
    ButtonSelector
} from "./style";
import { BsTrash } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

interface CardProductProps {
    id: number;
    image: string;
    title: string;
    price: number;
    qtd_carbon: number;
    minQty: number;
    maxQty: number;
    deleteItem: () => void;
    updateQuantity: (newQty: number) => void;
}

const CardProduct: React.FC<CardProductProps> = ({
    id,
    image,
    title,
    price,
    qtd_carbon,
    minQty,
    maxQty,
    deleteItem,
    updateQuantity
}) => {
    const { t } = useTranslation();
    const [quantity, setQuantity] = useState(qtd_carbon);
    const navigate = useNavigate();

    useEffect(() => {
        setQuantity(qtd_carbon);
    }, [qtd_carbon]);

    const increment = () => {
        if (quantity <= maxQty) {
            const newQuantity = quantity + 1;
            setQuantity(newQuantity);
            updateQuantity(newQuantity);
        }
    };

    const decrement = () => {
        if (quantity > minQty) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
            updateQuantity(newQuantity);
        }
    };

    return (
        <CardContainer>
            <div style={{ display: "flex", width: "70%" }}>
                <div>
                    <img
                        src={image}
                        alt={title}
                        style={{
                            width: "377px",
                            height: "247px",
                            borderRadius: "15px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigate(`/detailsPropertySeller/${id}`);
                        }}
                    />
                </div>
                <div style={{ marginLeft: "15px" }}>
                    <h2>{title}</h2>
                    <p style={{ marginTop: "80px" }}>
                        R${" "}
                        {price.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                        })}
                    </p>
                </div>
            </div>

            <div style={{ width: "30%", cursor: "default" }}>
                <div style={{ display: "flex", marginLeft: "30px" }}>
                    <ButtonSelector onClick={decrement} >-</ButtonSelector>
                    <Quantity>{quantity}</Quantity>
                    <ButtonSelector onClick={increment}>+</ButtonSelector>
                </div>
                <DivRemove>
                    <ButtonRemove onClick={deleteItem}>
                        <BsTrash size={25} style={{ color: "red" }} />
                        {t("remove")}
                    </ButtonRemove>
                </DivRemove>
            </div>
        </CardContainer>
    );
}

export default CardProduct;
