// src/components/AlertMap.tsx

import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useQuery, gql, useMutation } from "@apollo/client";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Tipagem para as variáveis de latitude e longitude
interface AlertMapProps {
    latitude: number;
    longitude: number;
    boundingBox: [number, number];
}

// Consulta GraphQL corrigida conforme a documentação
const GET_ALERTS = gql`
    query alerts($boundingBox: [Float!]) {
        alerts(boundingBox: $boundingBox) {
            collection {
                areaHa
                detectedAt
                publishedAt
                statusName
                alertCode
                alertGeometry {
                    code
                    __typename
                }
                crossedBiomes
                crossedStates
                crossedCities
                crossedQuilombos
                crossedQuilombosArea
                crossedSettlements
                crossedSettlementsArea
                crossedSettlementsRuralPropertiesList
                crossedForestManagementsArea
                crossedForestManagementsCategories
                crossedBiosphereReserves
                crossedBiosphereReservesArea
                crossedDeforestationAuthorizationsArea
                crossedIndigenousLandsArea
                crossedIndigenousLands
                crossedGeoparks
                crossedGeoparksArea
                crossedSpecialTerritories
                crossedSpecialTerritoriesArea
                crossedConservationUnits
                crossedConservationUnitsArea
                crossedRiverSourcesArea
                crossedEmbargoesRuralPropertiesTotal
                crossedEmbargoesRuralPropertiesArea
                crossedFederalProtectedAreaSustainableUses
                crossedFederalProtectedAreaSustainableUsesArea
                crossedFederalProtectedAreaIntegralProtections
                crossedFederalProtectedAreaIntegralProtectionsArea
                crossedStateProtectedAreaSustainableUses
                crossedStateProtectedAreaSustainableUsesArea
                crossedStateProtectedAreaIntegralProtections
                crossedStateProtectedAreaIntegralProtectionsArea
                crossedMunicipalityProtectedAreaSustainableUses
                crossedMunicipalityProtectedAreaSustainableUsesArea
                crossedMunicipalityProtectedAreaIntegralProtections
                crossedMunicipalityProtectedAreaIntegralProtectionsArea
                republished
                crossedRuralProperties {
                    id
                    code
                    type
                    areaHa
                    alertAreaInCar
                    __typename
                }
                sources
                boundingBox {
                    simplified
                    __typename
                }
                coordenates {
                    latitude
                    longitude
                }
                actions {
                    process
                    actionType
                    institutionName
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

const MUTATION_LOGIN = gql`
    mutation signIn($email: String!, $password: String!) {
        signIn(email: $email, password: $password) {
            token
        }
    }
`;

const AlertMap: React.FC<AlertMapProps> = ({
    latitude,
    longitude,
    boundingBox,
}) => {
    const [signIn, { data, loading, error }] = useMutation(MUTATION_LOGIN);

    useEffect(() => {
        const handleLogin = async () => {
            try {
                const response = await signIn({
                    variables: {
                        email: "luiz.augusto@ecolifemeioambiente.com.br",
                        password: "Yjp#K5YX.*8dce.",
                    },
                });
                localStorage.setItem("authToken", response.data.signIn.token);
            } catch (err) {
                console.error("Login failed:", err);
            }
        };

        handleLogin();
    }, [signIn]);

    const {
        loading: alertsLoading,
        error: alertsError,
        data: alertsData,
    } = useQuery(GET_ALERTS, {
        variables: { boundingBox },
    });

    const [alerts, setAlerts] = useState<any[]>([]);

    useEffect(() => {
        if (alertsData && alertsData.alerts) {
            setAlerts(alertsData.alerts.collection);
        }
    }, [alertsData]);

    if (alertsLoading) return <div>Loading...</div>;
    if (alertsError) return <div>Error: {alertsError.message}</div>;

    return (
        <MapContainer
            center={[latitude, longitude]}
            zoom={15}
            style={{
                height: "500px",
                width: "100%",
            }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {alerts.map((alert) => (
                <Marker
                    key={alert.alertCode}
                    position={[
                        alert.coordenates.latitude,
                        alert.coordenates.longitude,
                    ]}
                    icon={L.icon({
                        iconUrl:
                            "https://cdn-icons-png.flaticon.com/512/7782/7782342.png",
                        iconSize: [25, 25],
                        iconAnchor: [12, 12],
                        popupAnchor: [0, -12],
                    })}
                >
                    <Popup>
                        <strong>Code: {alert.alertCode}</strong>
                        <br />
                        Status: {alert.statusName}
                        <br />
                        <small>Data: {alert.publishedAt}</small>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default AlertMap;
