import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start; 
  padding-left: 10px;
  width: 750px;    
  height: 200px;
  cursor: pointer;
`;

export const Img = styled.img`
  width: 360px;
  height: 100%;
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column; 
  flex-grow: 1;
  padding-left: 20px; 
`;

export const Title = styled.h2`
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  color: #50700F;
`;

export const PostDate = styled.p`
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  align-self: flex-start; /* Alinha a data à esquerda */
  margin-top: 40px; 
  color: black;
`;