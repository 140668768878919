import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsFileText } from "react-icons/bs";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import {
    Button,
    Language,
    MarginRight,
    TextInformation,
    Title,
    Content,
    NavbarText,
    Welcome,
    Pointer,
    Container,
    DropdownLanguage,
    NavbarTextLogin,
    Logo,
    IconOrder,
    ButtonNewProperty
} from "./style";
import { IoCartOutline } from "react-icons/io5";
import { useMeData } from "../../hooks/serviceMe";
import { useAuth } from "../../context/AuthContext";

const ic_logo = require("../../assets/images/new-logo-colorida.png");

const NavBar: React.FC = () => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const type = localStorage.getItem("userType");
    const token = localStorage.getItem("token");
    const { data, isLoading } = useMeData();
    const [isOpen, setIsOpen] = useState(false);
    const [navHeight, setNavHeight] = useState(0);
    const { logout } = useAuth();
    const { user } = useAuth();

    const navRef = createRef<HTMLDivElement>();

    const changeLanguage = (lng: string) => {
        localStorage.setItem("language", lng);
        i18n.changeLanguage(lng);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const handleLogout = () => {

        logout();
    }

    useEffect(() => {
        const fetchLanguage = async () => {
            const storedLanguage = await localStorage.getItem("language");
            if (storedLanguage) {
                i18n.changeLanguage(storedLanguage);
            }
        };

        fetchLanguage();
    }, [i18n]);

    useEffect(() => {
        if (!navRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setNavHeight(
                navRef.current?.getBoundingClientRect().height || navHeight
            );
        });
        resizeObserver.observe(navRef.current);
        return () => resizeObserver.disconnect();
    }, [navRef, navHeight]);

    return (
        <>
            <Container ref={navRef}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    {token && type !== "satelite" && (
                        <>
                            {t("greetings-user")}&nbsp;
                            {user?.fullname}&nbsp;-&nbsp;
                            <Button
                                style={{
                                    color: "#4E9600",
                                    width: "auto",
                                    padding: "0 10px",
                                }}
                                onClick={() => handleLogout()}
                            >
                                {t("logout")}
                            </Button>
                        </>
                    )}
                    <Language>
                        <DropdownLanguage
                            onChange={(e) => changeLanguage(e.target.value)}
                            defaultValue={i18n.language}
                        >
                            <option value="pt">PT-BR</option>
                            <option value="en">EN-US</option>
                        </DropdownLanguage>
                    </Language>
                </div>
                <Content>
                    <NavbarText
                        onClick={() => {
                            navigate("");
                        }}
                        href="/"
                    >
                        <Logo
                            src={ic_logo}
                            alt="Logo"
                            onClick={() => {
                                token && navigate("/homeBuy");
                            }}
                        />
                    </NavbarText>
                    <NavbarText
                        onClick={() => {
                            navigate("/homeBuy");
                        }}
                    >
                        {t("Home")}
                    </NavbarText>
                    <NavbarText
                        onClick={() => {
                            navigate("/pronouncement");
                        }}
                    >
                        {t("Pronouncements")}
                    </NavbarText>

                    {type !== "seller" && (
                        <NavbarText
                            onClick={() => {
                                navigate("/news");
                            }}
                        >
                            {t("properties")}
                        </NavbarText>
                    )}
                    <NavbarText
                        onClick={() => {
                            navigate("/catalog");
                        }}
                    >
                        {t("catalog")}
                    </NavbarText>
                    <NavbarText
                        onClick={() => {
                            navigate("/satelite");
                        }}
                    >
                        {t("satelite")}
                    </NavbarText>
                    <NavbarText
                        onClick={() => {
                            navigate("/contact");
                        }}
                    >
                        {t("contact-us")}
                    </NavbarText>

                    {token && (
                        <>
                            <NavbarText
                                onClick={() => {
                                    navigate("/mySpace");
                                }}
                            >
                                {type === "satelite" && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: "0",
                                        }}
                                    >
                                        <Pointer
                                            onClick={() => {
                                                navigate("/choice");
                                            }}
                                        >
                                            <NavbarTextLogin>
                                                {t("login")} | {t("sign-up")}
                                            </NavbarTextLogin>
                                        </Pointer>
                                    </div>
                                )}
                                {type !== "satelite" && t("my-space")}
                            </NavbarText>

                            {type === "seller" && (
                                <NavbarText
                                    onClick={() => {
                                        navigate("/registerProperty");
                                    }}
                                >
                                    <ButtonNewProperty>
                                        {t("register-property")}
                                    </ButtonNewProperty>
                                </NavbarText>
                            )}

                            {type === "buyer" && (
                                <NavbarText
                                    onClick={() => {
                                        navigate("/order");
                                    }}
                                >
                                    <IconOrder>
                                        <BsFileText size={28} />
                                    </IconOrder>
                                </NavbarText>
                            )}
                        </>
                    )}

                    <Welcome>
                        {token && type === "cliente" ? (
                            <NavbarText
                                onClick={() => {
                                    navigate("/order");
                                }}
                            >
                                <div
                                    style={{
                                        border: "2px solid #50700F",
                                        borderRadius: "50%",
                                        padding: "10px",
                                    }}
                                >
                                    <IoCartOutline size={25} color="#50700F" />
                                </div>
                            </NavbarText>
                        ) : (
                            ""
                        )}
                        {!isLoading && (
                            <>
                                <span>
                                    {!token && (
                                        <div>
                                            <Pointer
                                                onClick={() => {
                                                    navigate("/choice");
                                                }}
                                            >
                                                <NavbarTextLogin>
                                                    {t("login")} |{" "}
                                                    {t("sign-up")}
                                                </NavbarTextLogin>
                                            </Pointer>
                                        </div>
                                    )}
                                    {token && type === "cliente" ? (
                                        data && (
                                            <Pointer
                                                onClick={() => {
                                                    token
                                                        ? navigate("/profile")
                                                        : navigate("/choice");
                                                }}
                                            >
                                                {data?.corporate_name
                                                    ? data?.corporate_name
                                                    : data?.name +
                                                      " " +
                                                      data?.surname}
                                            </Pointer>
                                        )
                                    ) : token && type === "vendedor" ? (
                                        <Pointer
                                            onClick={() => {
                                                token
                                                    ? navigate("/profile")
                                                    : navigate("/choice");
                                            }}
                                        >
                                            {data?.corporate_name
                                                ? data?.corporate_name
                                                : data?.business_name}
                                        </Pointer>
                                    ) : token && type === "engenheiro" ? (
                                        <Pointer
                                            onClick={() => {
                                                token
                                                    ? navigate("/profile")
                                                    : navigate("/choice");
                                            }}
                                        >
                                            {data?.corporate_name
                                                ? data?.corporate_name
                                                : data?.business_name}
                                        </Pointer>
                                    ) : null}
                                </span>
                            </>
                        )}
                        {isLoading && <NavbarText>Carregando...</NavbarText>}
                    </Welcome>
                </Content>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay style={{ width: "100%", height: "100%" }} />
                    <ModalContent>
                        <ModalHeader>
                            <Title>Atenção</Title>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <TextInformation>
                                {" "}
                                Para acessar essa funcionalidade é necessário
                                estar logado. Faça o login ou se cadastre na
                                plataforma!
                            </TextInformation>
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={onClose}>Fechar</Button>
                            <MarginRight />
                            <Button onClick={() => navigate("/choice")}>
                                Login ou Cadastrar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Container>
        </>
    );
};

export default NavBar;
