import styled from "styled-components";
import colors from "../../../utils/colors";
import InputMask from 'react-input-mask';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerCard = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${colors.gray};
  border-radius: 10px;
  max-height: 300px;
  max-width: 250px;
  cursor: pointer;
  padding: 10px;

`;

export const CardImage = styled.div<{
  src: string;
}>`
  width: 218px;
  height: 144px;
  border-radius: 10px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

export const CardTitle = styled.h3`
  font-size: 18px;
  color: ${colors.text};
  font-family: "poppins";
`;

export const ContentCard = styled.div`
  width: 85%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  font-family: "poppins";
  p {
    text-overflow: ellipsis;
  }
`;

export const Title = styled.h1`
  color: ${colors.black};
  font-size: 30px;
  padding: 0;
  margin: 0;
  font-family: "poppins";
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colunas com largura igual */
  margin-left: 30px;
`;

export const Input = styled.input`
  border: 1px solid ${colors.black};
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #50700F;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10px;
    padding-right: 80px;
    position: relative;

    &:not(:last-child)::after {
      content: ' ';
      border-right: 4px solid ${colors.primary};
      height: 100%;
      position: absolute;
      right: 20px;
    }

    & > input {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`;

export const InputLabel = styled.label`
  padding: 0px;
  font-family: "poppins";
`;

export const InputError = styled.p`
  background-color: ${colors.danger};
  color: white;
  padding: 6px;
  margin-left: 20px;
  width: fit-content;
  font-family: "poppins";
  &:empty {
    display: none;
  }
`;

export const Button = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border-radius: 10px;
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 200px;
  font-family: "poppins";
`
export const ButtonSecond = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border-radius: 10px;
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  font-family: "poppins";
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const Select = styled.select`
    border: 1px solid ${colors.black};
    border-radius: 10px;
    width: 100%;
    height: 40px;
    padding: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #50700F;
    background-color: white;
`;

//Css da tela de Cartões


export const ButtonAddCard = styled.button`
    display: flex;
    align-items: center;  
    justify-content: center;  
    border: 1px solid ${colors.black};
    margin-bottom: 40px;
    border-radius: 10px;
    width: 500px;
    height: 85px;
    color: #0788D1;
`;

export const DataButton = styled.button`
  display: flex;
  font-size: 30px;
  gap: 60px;
  font-family: "poppins";
`;

export const CardContainer = styled.button`
  display: flex;
  gap: 350px;
`;

//Cadastro do cartão
export const ContainerForms= styled.div`
  margin: 30px;
  display: flex;
  border: 1px solid #534B4B;
  border-radius: 5px;
  height: 430px;
  width: 740px;
  padding-top: 50px;
  padding-left: 80px;
`;

export const ButtonSubmit = styled.button`
  background-color: #45B7FE;
  height: 70px;
  width: 325px;
  border-radius: 10px;
  color: white;
  font-size: 24px;
  margin-left: 25%;
  margin-top: 30px;
`;

export const FieldInput = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

export const InputIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    left: 90%; 
    color: #50700F; 
  }
`;

export const ConteinerField = styled.div`
  display: flex;
  gap: 55px;
`;

export const Inputs = styled.input`
  width: 580px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 18px;
  color: #50700F;
  margin-left: 0;
`;

export const StyledInputMaskNumberCard = styled(InputMask)`
  width: 670px;
  height: 55px;
  border-radius: 5px;
  border: 1px solid #534B4B;
  outline: none;
  font-size: 22px;
  color: #50700F;
  margin-left: 15px;
`;

export const StyledInputMask = styled(InputMask)`
  width: 300px;
  height: 55px;
  border-radius: 5px;
  border: 1px solid #534B4B;
  outline: none;
  font-size: 22px;
  color: #50700F;
  margin-left: 15px;

  &::placeholder{
    color: #50700F;
  }
`;

export const LabelCard = styled.label`
font-size: 18px;
`;


