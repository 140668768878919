import styled from "styled-components";

export const TitleComponent = styled.div`
    display: flex;
    margin: 30px;
`;

export const Title = styled.h1`
    font-family: "poppins";
    color: #50700f;
    font-size: 25px;
    padding-left: 5px;
`;
export const Container = styled.div`
    display: flex;
`;

export const ContainerContact = styled.div`
    font-family: "poppins";
    font-size: 25px;
    border: 1px solid #ede2e2;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
    align-items: center;
    width: 100%;
    ul {
        display: flex;
        gap: 20px;
        width: 100%;
        height: 65vh;
        margin-left: 0px;
    }
`;

export const Text = styled.li`
  font-family: "poppins";
  color: #50700F;
  font-size: 25px;
  text-align: center;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  margin: 5px;
  width: 25%;

  p {
    width: 100%;
    word-wrap: break-word
    overflow: hidden;
  }
`;

export const Line = styled.div`
    width: 1px;
    background-color: #ede2e2;
    height: 80%;
    margin-top: 60px;
`;

export const ContainerInputs = styled.div`
    display: flex;
    flex-wrap: wrap; /* Permite que os itens se ajustem conforme necessário */
    font-family: "poppins";
    font-size: 25px;
    border: 1px solid #ede2e2;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
`;

export const Input = styled.input`
    width: calc(50% - 60px);
    height: 60px;
    font-family: "poppins";
    color: #50700f;
    border: 1px solid #ede2e2;
    border-radius: 10px;
    font-size: 22px;
    padding-left: 10px;
    margin-right: 60px;
    margin-bottom: 20px;
    outline: none;

    &::placeholder {
        color: #50700f;
    }
    &.fullWidth {
        width: 1345px;
        height: 200px;
        padding-bottom: 150px;
        margin-bottom: 70px;
    }
`;

export const ButtonSend = styled.button`
    background-color: #45b7fe;
    font-family: "poppins";
    width: 500px;
    height: 60px;
    color: white;
    border-radius: 10px;
    font-size: 25px;
    align-self: flex-start; /* Alinha o botão à esquerda */
    margin-top: 20px; /* Espaço entre os inputs e o botão */
`;
