import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  flex: 0;
`;

export const Wrapper = styled.div`
  display: grid;
  flex-direction: row;
  margin-top: 30px;
  grid-template-columns: minmax(30vw, auto) 1fr;
`;

export const Main = styled.div`
`;


export const List = styled.ul`
  display: block;
  width: 90%;
`;

export const ListItem = styled.li<{
  active?: boolean,
}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${colors.black};
  border-radius: 5px;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${props => props.active ? colors.primary : 'transparent'};

  &:hover {
    background-color: ${colors.primary};
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-left: 100px;
  margin-bottom: 40px;
  font-weight: bold;
`;

export const Icon = styled.img`
  margin-right: 10px;
  display: inline-block;
  width: auto;
  height: 40px;
`;

export const NavTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavTitle = styled.h3`
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 8px;
`;
export const NavSubtitle = styled.h4`
  font-size: 15px;
  margin: 0;
  padding: 0;
  margin-top: -12px;
`;
