const colors = {
  primary: "#E1E7D6",
  secondary: "#45B7FE",
  black: "#000",
  gray: "#8F8F8F",
  text: "#50700F",
  green:"50700F",
  textGary: "#515151",
  warning: "#F5C723",
  danger: "#EE7575",
  success: "#52B788",
  info: "#96D2F4",
};

export default colors;
