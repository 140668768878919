
import { useTranslation } from "react-i18next";
import {
    CardContainer,
    ButtonRemove,
    DivRemove
} from "./style";
import { BsTrash } from "react-icons/bs";

interface CardFavoriteProps {
    image: string;
    title: string;
    price: number;
    is_favorite: boolean;
    onClick: () => void;
    deleteItem: () => void;
}

const CardFavorite: React.FC<CardFavoriteProps> = ({ image, title, price, is_favorite, onClick, deleteItem }) => {
    const { t } = useTranslation();

    return (
        <CardContainer >
            <div style={{display:"flex", width:"90%"}} onClick={onClick}>
                <div>
                    <img src={image} alt={title} style={{ width: "290px", height: "234px", borderRadius: "15px" }} />
                </div>
                <div style={{marginLeft:"15px"}}>
                    <h2>{title}</h2>
                    <p style={{ marginTop: "80px" }}>R$: {price}</p>
                </div>
            </div>
            <DivRemove onClick={deleteItem}>
                <ButtonRemove><BsTrash size={25} style={{ color: "red" }} />{t("remove")}</ButtonRemove>
            </DivRemove>
        </CardContainer>
    );
}

export default CardFavorite;
