import styled from "styled-components";

export const ContainerTitle = styled.div`
  display: flex;
  margin: 30px;
`;

export const Title = styled.h1`
  font-family: "poppins";
  color: #50700F;
  font-size: 25px;
  padding-left: 5px;
`;
