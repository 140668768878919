
import { Container, Img, Content, Title, PostDate } from "./postSecondStyle";
import { storageServerPath } from "../../utils/server/api";

export interface PostData {
  id: number;
  title: string;
  created_at: string;
  image: string;
  link: string;
}

function formatDate(dateString: string) {
    const months = [
        "janeiro",
        "fevereiro",
        "março",
        "abril",
        "maio",
        "junho",
        "julho",
        "agosto",
        "setembro",
        "outubro",
        "novembro",
        "dezembro",
    ];

    const date = new Date(dateString);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} de ${month} de ${year}`;
}

const PostSecond = ({ data }: { data: PostData }) => {
  return (
      <Container>
          <a href={data.link} target="_blank" rel="noreferrer">
              <Img
                  src={`${storageServerPath}base64-files/pronouncements/${data.image}`}
                  alt={data.title}
              />
          </a>
          <Content>
              <a href={data.link} target="_blank" rel="noreferrer">
                  <Title>{data.title}</Title>
              </a>
              <PostDate>{formatDate(data.created_at)}</PostDate>
          </Content>
      </Container>
  );
};

export default PostSecond;