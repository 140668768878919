import { useState } from "react";
import {
    ButtonEnter,
    Container,
    Content,
    LinhaVertical,
    Space,
    TitleButtonEnter,
    TitleInput,
    Title,
    ErrorText,
    MarginBottom,
    Img,
    DivImg,
} from "./style";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { ContainerMargin } from "../cart/style";
import logo from "../../assets/images/new-logo.png";
import {
    Formik,
    Field,
    Form,
    FormikHelpers,
    FieldInputProps,
    ErrorMessage,
} from "formik";
import { Center } from "@chakra-ui/react";

import { BeatLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PasswordInputWithToggle from "../../components/password-input-with-toggle/password-input-with-toggle";
import { useServiceUser } from "../../hooks/serviceUser";

export default function RecoverPassword() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token") || "";

    const initialValues = {
        new_password_confirm: "",
        password: "",
    };

    const { recoverPassword } = useServiceUser();

    const validate = (values: typeof initialValues) => {
        const errors: any = {};

        if (!values.password) {
            errors.password = t("password-required");
        } else if (values.password.length < 6) {
            errors.password = t("password-min-length");
        }

        if (!values.new_password_confirm) {
            errors.new_password_confirm = t("password-confirm-required");
        } else if (values.password !== values.new_password_confirm) {
            errors.new_password_confirm = t("password-not-equal");
        }

        return errors;
    };

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        setIsLoading(true);
        recoverPassword({
            password: values.password,
            code: token,
        })
            .then((response) => {
                toast.success(t("successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                });
                navigate("/choice");
            })
            .catch((error: any) => {
                if (error.response && error.response.data) {
                    const errors = error.response.data.errors;
                    const errors2 = error.response.data.message;

                    if (errors2 && !errors) {
                        toast.error(t(errors2), {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }

                    if (errors) {
                        Object.keys(errors).forEach((field) => {
                            errors[field].forEach((errorMessage: string) => {
                                toast.error(t(errorMessage), {
                                    position: toast.POSITION.TOP_CENTER,
                                });
                            });
                        });
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Container>
            <Navbar />
            <Container>
                <Content>
                    <Space>
                        <DivImg>
                            <Img src={logo} alt="Ecolife" />
                        </DivImg>
                    </Space>
                    <LinhaVertical />
                    <Space>
                        <Title>{t("recover-password")}</Title>
                        <ContainerMargin>
                            <Formik
                                validate={validate}
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                            >
                                <Form>
                                    <TitleInput>{t("new-password")}</TitleInput>
                                    <Field name="password">
                                        {({
                                            field,
                                        }: {
                                            field: FieldInputProps<any>;
                                        }) => (
                                            <PasswordInputWithToggle
                                                field={field}
                                                placeholder={t("type-password")}
                                            />
                                        )}
                                    </Field>
                                    <ErrorText>
                                        <ErrorMessage name="password" />
                                    </ErrorText>
                                    <MarginBottom />
                                    <TitleInput>
                                        {t("confirm-new-password")}
                                    </TitleInput>
                                    <Field name="new_password_confirm">
                                        {({
                                            field,
                                        }: {
                                            field: FieldInputProps<any>;
                                        }) => (
                                            <PasswordInputWithToggle
                                                field={field}
                                                placeholder={t("type-password")}
                                            />
                                        )}
                                    </Field>
                                    <ErrorText>
                                        <ErrorMessage name="new_password_confirm" />
                                    </ErrorText>

                                    <MarginBottom />

                                    <Center>
                                        <ButtonEnter type="submit">
                                            {isLoading ? (
                                                <BeatLoader
                                                    className="spinner"
                                                    size={8}
                                                    color={"#fff"}
                                                />
                                            ) : (
                                                <TitleButtonEnter>
                                                    {t("save")}
                                                </TitleButtonEnter>
                                            )}
                                        </ButtonEnter>
                                    </Center>
                                </Form>
                            </Formik>
                        </ContainerMargin>
                    </Space>
                </Content>
                <ToastContainer />
            </Container>
            <Footer />
        </Container>
    );
}
