import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const ContentQrcode = styled.div`
    display: flex;
    height: 680px;
    width: 1090px;
    border: 1px solid #EDE2E2; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
`;

export const ButtonBack = styled.button`
display: flex;
  width: 179px;
  height: 48px;
  background-color: #E1E7D6;
  color: black;
  border-radius: 10px;
  font-size: 16px;
  font-family: "poppins";
  align-items: center;
  justify-content: center;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 10px;
`;

export const InputField = styled.input`
  flex: 1;
  border: none;
  outline: none;
  height: 48px;
  font-size: 16px;
  background-color: #E7ECDF;
  border-radius: 5px 10px 10px 5px;
  cursor: pointer;
`;

export const CopyButton = styled.button`
  background-color: #45B7FE;
  color: #fff;
  border: none;
  border-radius: 10px 5px 5px 10px;
  padding: 7px 12px;
  height: 48px;
  font-size: 14px;
  cursor: pointer;
`;

export const ButtonBankSlip = styled.button`
  color: red;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
`;