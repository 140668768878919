import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  width: 98%;
  height: 100px;
  display: flex;
  margin: 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 110px;
`;

export const Container = styled.div`
  display: flex; 
  background-color: #fff;
  width: 100%; 
  height: 270px;
  border-radius: 10px;
`;

export const Contentt = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: row;
`;

export const ContainerText = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px 40px 40px; 
`;

export const ContainerImg = styled.div`
  width: 50%;
  height: 100%;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 14px; 
  font-family: "Poppins"; 
  color: #50700F;
  padding-bottom: 10%;
`;

export const Img = styled.img`
  margin-top: 5px;
  height: 100%;
  width: 100%;
`;
