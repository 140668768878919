import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const TitleInput = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const Placeholder = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-family: "poppins";
  color: "#515151";
  pointer-events: none;
`;

export const SearchIncorrect = styled.div`
  justify-content: center;
  align-items: center;
  width: 100vh; // Isso fará com que ocupe a altura total da viewport
  margin-left: 70%;
  margin-top: 15%;
  margin-bottom: 15%;

`;

export const Title = styled.h1`
  color: #50700F;
  font-size: 30px;
`;
export const SubTitle = styled.h2`
  color: #50700F;
  font-size: 20px;
`;

export const ButtonNovidades = styled.button`
  color: #0788D1;
  font-size: 20px;
  cursor: pointer;
`;


export const ContainerCard = styled.div`
  margin: 30px;
  overflow-x: auto;
  white-space: nowrap;
  grid-column: span 1; // Todos os cards ocupam 1 espaço por padrão
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas com largura igual */
  margin-left: 30px;
`;

export const ContentCard = styled.div`
  margin-bottom: 30px; /* Margem apenas na parte inferior para espaçamento vertical */
`;

export const CardWrapper = styled.div`
  display: inline-block; /* Faz os itens ficarem na mesma linha */
  margin-right: 16px; /* Adiciona um espaçamento entre os itens */
`;