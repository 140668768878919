import React from 'react';
import { CardContainer, CardContent, CardContentRight, ButtonTrash } from "./style";
import { FaRegCreditCard } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

interface CardProps {
  surname: string;
  cardNumber: string;
  deleteItem: () => void;
  isSelected?: boolean;
  onSelect?: () => void;
  isSelectionEnabled?: boolean;
}

const Card: React.FC<CardProps> = ({
  surname,
  cardNumber,
  deleteItem,
  isSelected,
  onSelect,
  isSelectionEnabled = false,
}) => {
  const maskCardNumber = cardNumber.replace(/.*(\d{4})$/, '**** **** **** $1');
  const { t } = useTranslation();

  return (
      <CardContainer
          onClick={isSelectionEnabled ? onSelect : undefined}
          style={{
              backgroundColor:
                  isSelectionEnabled && isSelected ? "#E1E7D6" : "transparent",
          }}
      >
          <CardContent>
              <p>
                  {t("alias-card")}: {surname}
              </p>
              <p>{maskCardNumber}</p>
          </CardContent>

          <CardContentRight>
              <ButtonTrash onClick={deleteItem}>
                  <BsTrash size={30} />
              </ButtonTrash>
              <FaRegCreditCard size={40} />
          </CardContentRight>
      </CardContainer>
  );
};

export default Card;
