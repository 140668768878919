import styled from "styled-components";
import Select from "react-select";

export const SelectDrop = styled(Select)`
  width: 578px;
  height: 50px;
`;

export const SelectContainer = styled.div`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  width: 100%;
  height: 50px;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
`;
