import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Footer from "../../../components/footer/footer";
import NavBar from "../../../components/navbar/navbar";
import { useServiceProperty } from "../../../hooks/serviceProperty";
import { AppProperty } from "../../../models/Property";
import {
    BannerContainer,
    Container,
    ContentContainer,
    HeaderContainer,
    HeaderTable,
    Main,
    MetaContainer,
    MetaItem,
    Title,
} from "./style";
import { storageServerPath } from "../../../utils/server/api";
import Gallery from "../../../components/gallery/gallery";
import { useAuth } from "../../../context/AuthContext";
import ModalConfirmation from "../../../components/alerts/modalConfirmation";

const mapIcon = require("../../../assets/images/map-icon.png");

export default function DetailsProperty() {
    const { id } = useParams();
    const { t } = useTranslation();
    const { getProperty, removeProperty } = useServiceProperty(id);
    const [property, setProperty] = useState<AppProperty>();
    const { user } = useAuth();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const navigate = useNavigate();
    const lng = localStorage.getItem("language");

    const openMap = () => {
        window.open(`https://www.google.com/maps/place/${property?.address}`);
    };

    const alterOpenModalDelete = (value: boolean, status: boolean) => {
        setOpenModalDelete(value);

        if (status === true) {
            removeProperty().then(() => {
                navigate("/mySpaceSeller/environmental-portfolio");
            });
        }
    };

    useEffect(() => {
        async function fetchProperty() {
            const data = await getProperty();
            setProperty(data);
        }

        fetchProperty();
    }, []);

    return (
        <Container>
            <NavBar />
            <Breadcrumb noHomeIcon itens={[t("property-details")]} />

            {property && (
                <Main>
                    <BannerContainer>
                        <Gallery
                            images={property?.file_images?.split(";")}
                            url={storageServerPath}
                        />
                    </BannerContainer>
                    <HeaderContainer>
                        <Title>
                            {lng === "en" ? property.name_en : property.name}
                        </Title>
                        <HeaderTable>
                            <div>
                                <strong>{t("price")}</strong>{" "}
                                <p>{property.cost_formatted}</p>
                            </div>

                            {/*<div><strong>{t("carbon-credit")}</strong> <p>{property}</p></div>*/}

                            <div>
                                <strong>{t("address")}</strong>{" "}
                                <p>{property.address}</p>
                            </div>

                            <div>
                                <strong>{t("hectares")}</strong>{" "}
                                <p>{property.acre}</p>
                            </div>
                        </HeaderTable>
                    </HeaderContainer>

                    <MetaContainer>
                        <MetaItem onClick={openMap}>
                            <img src={mapIcon} alt="Map Icon" />
                            <p>{t("map-track")}</p>
                        </MetaItem>
                        <MetaItem
                            onClick={() =>
                                window.open(
                                    storageServerPath + property.file_cib
                                )
                            }
                        >
                            <p>CIB</p>
                        </MetaItem>
                        <MetaItem
                            onClick={() =>
                                window.open(
                                    storageServerPath +
                                        property.file_deed_number
                                )
                            }
                        >
                            <p>{t("deed-file")}</p>
                        </MetaItem>
                        <MetaItem
                            onClick={() =>
                                window.open(
                                    storageServerPath +
                                        property.file_proof_of_residence
                                )
                            }
                        >
                            <p>{t("proof-residence")}</p>
                        </MetaItem>
                        {user?.user_type === "seller" && (
                            <>
                                <MetaItem
                                    style={{ backgroundColor: "blue" }}
                                    onClick={() =>
                                        navigate(
                                            `/updateProperty/${property.id}`
                                        )
                                    }
                                >
                                    <p
                                        style={{
                                            color: "#fff",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("update")}
                                    </p>
                                </MetaItem>
                                <MetaItem
                                    style={{ backgroundColor: "red" }}
                                    onClick={() => {
                                        setOpenModalDelete(true);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "#fff",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("delete")}
                                    </p>
                                </MetaItem>
                            </>
                        )}
                    </MetaContainer>
                    <ModalConfirmation
                        show={openModalDelete}
                        alterOpenModalDelete={alterOpenModalDelete}
                        message={t("delete-confirmation")}
                    />
                    <ContentContainer>
                        <Title>
                            {lng === "en" ? property.name_en : property.name}
                        </Title>

                        <div>
                            <Title style={{ textDecoration: "underline" }}>
                                {t("details")}
                            </Title>

                            <p>
                                {lng === "en"
                                    ? property.description_en
                                    : property.description}
                            </p>
                        </div>
                    </ContentContainer>
                </Main>
            )}

            <Footer />
        </Container>
    );
}
