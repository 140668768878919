import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Container, Content } from "./style";
import { useLocation } from "react-router-dom";
import AlertMap from "../../components/mapBiomas/MapBiomas";
import client from "../../components/mapBiomas/apolloClient";
import { ApolloProvider } from "@apollo/client";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function MapBiomas() {
    const query = useQuery();
    const lat = parseFloat(query.get("lat") || "-15.7942");
    const lng = parseFloat(query.get("lng") || "-47.8822");

    return (
        <Container>
            <NavBar />
            <Content
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <ApolloProvider client={client}>
                    <div className="App" style={{ flex: 2 }}>
                        <h1>Alertas MapBiomas</h1>
                        <AlertMap
                            latitude={lat}
                            longitude={lng}
                            boundingBox={[lng, lat]}
                        />
                    </div>
                </ApolloProvider>
            </Content>
            <Footer />
        </Container>
    );
}
