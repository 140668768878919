import React, { useEffect, useState } from "react";
import {
    Container,
    Input,
    InputContainer,
    InputLabel,
    InputError,
    Button,
    Center,
    Select,
} from "./style";

import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useServiceUser } from "../../../hooks/serviceUser";
import { toast, ToastContainer } from "react-toastify";

export default function MySpaceBank() {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { updateDataBankUser } = useServiceUser();
    const [bancos, setBancos] = useState([]);

    useEffect(() => {
        fetch("/bankList.json")
            .then((response) => response.json())
            .then((data) => setBancos(data))
            .catch((error) => console.error("Erro ao buscar bancos:", error));
    }, []);

    if (!user) {
        return null;
    }

    const initialValues = {
        pix_key: user?.bankData?.pix_key || "",
        pix: user?.bankData?.pix || "",
        agency: user?.bankData?.agency || "",
        account: user?.bankData?.account || "",
        bank: user?.bankData?.bank || "",
        document: user?.bankData?.document || "",
        favored: user?.bankData?.favored || "",
    };

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        const data = {} as Parameters<typeof updateDataBankUser>[0];

        if (values.pix_key.length > 0) {
            data.pix_key = values.pix_key;
        }

        if (values.pix.length > 0) {
            data.pix = values.pix;
        }

        if (values.agency.length > 0) {
            data.agency = values.agency;
        }

        if (values.account.length > 0) {
            data.account = values.account;
        }

        if (values.bank.length > 0) {
            data.bank = values.bank;
        }

        if (values.document.length > 0) {
            data.document = values.document;
        }

        if (values.favored.length > 0) {
            data.favored = values.favored;
        }

        try {
            await updateDataBankUser(values);
            toast.success(t("successfully"), {
                position: toast.POSITION.TOP_CENTER,
            });
        } catch (error: any) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
                const errors = error.response.data.errors;
                Object.entries(errors).forEach(([field, messages]:[field: any, messages:any]) => {
                    messages.forEach((message: any) => {
                        toast.error(t(message), {
                            position: "top-right",
                        });
                    });
                });
            }
        }
    };

    return (
        <Container>
            <ToastContainer />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                    <InputContainer>
                        <div>
                            <InputLabel>{t("type-pix-key")}:</InputLabel>
                            <Field
                                as={Select}
                                type="text"
                                name="pix_key"
                                required
                            >
                                <option value="">Selecione</option>
                                <option value="cpf">CPF</option>
                                <option value="cnpj">CNPJ</option>
                                <option value="phone">{t("phone")}</option>
                                <option value="email">{t("email")}</option>
                                <option value="random">{t("random")}</option>
                            </Field>
                            <InputError>
                                <ErrorMessage name="pix_key" />
                            </InputError>

                            <InputLabel>{t("pix-key")}:</InputLabel>
                            <Field as={Input} type="text" name="pix" />
                            <InputError>
                                <ErrorMessage name="pix" />
                            </InputError>

                            <InputLabel>{t("bank")}</InputLabel>
                            <Field as={Select} type="text" name="bank">
                                <option value="">Selecione</option>
                                {bancos.map((banco: any, index: number) => (
                                    <option
                                        key={index}
                                        value={banco.codigo_compe}
                                    >
                                        {banco.codigo_compe} - {banco.banco}
                                    </option>
                                ))}
                            </Field>
                            <InputError>
                                <ErrorMessage name="bank" />
                            </InputError>

                            <InputLabel>{t("agency")}:</InputLabel>
                            <Field as={Input} type="text" name="agency" />
                            <InputError>
                                <ErrorMessage name="agency" />
                            </InputError>

                            <InputLabel>{t("account")}:</InputLabel>
                            <Field as={Input} type="text" name="account" />
                            <InputError>
                                <ErrorMessage name="account" />
                            </InputError>
                        </div>

                        <div>
                            <InputLabel>CPF</InputLabel>
                            <Field as={Input} type="text" name="document" />
                            <InputError>
                                <ErrorMessage name="document" />
                            </InputError>

                            <InputLabel>{t("favored")}:</InputLabel>
                            <Field as={Input} type="text" name="favored" />
                            <InputError>
                                <ErrorMessage name="favored" />
                            </InputError>
                        </div>
                    </InputContainer>

                    <Center>
                        <Button type="submit">{t("update")}</Button>
                    </Center>
                </Form>
            </Formik>
        </Container>
    );
}
