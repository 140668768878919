import React from "react";
import {
    Title,
    ContainerNoItems,
} from "./style";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";

export default function PaySuccess() {
    const { t } = useTranslation();

    return (
        <>
            <Navbar />
            <Breadcrumb itens={[t("order")]} />
                <ContainerNoItems>
                    <Title>{t('payment_success')}</Title>
                </ContainerNoItems>
            <Footer />
        </>
    );
}
