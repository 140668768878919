import { useEffect, useState } from "react";
import "./style.css";

const Gallery = ({ images, url }:{images:any, url:any}) => {
    const [selectedImage, setSelectedImage] = useState(
        images && images[0] ? images[0] : null
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [concludedImage, setConcludedImage] = useState(false);

    const handleThumbnailClick = (image: any) => {
        setSelectedImage(image);
    };

    const handleImageClick = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const clearImage = async () => {
            if (images) {
                const validImages = images.filter((image: any) => image);

                const imageToGallery = await Promise.all(
                    validImages.map(async (image: any) => {
                        return image;
                    })
                );

                setConcludedImage(true);
                return imageToGallery;
            }

            return [];
        };

        const fetchImages = async () => {
            const imageToGallery = await clearImage();
            setSelectedImage(imageToGallery[0]);
        };

        if (!concludedImage) {
            fetchImages();
        }
    }, [concludedImage, images]);

    return (
        <div className="gallery-container">
            <div className="main-image-container">
                <img
                    src={url + selectedImage}
                    alt="Main"
                    className="main-image"
                    onClick={handleImageClick}
                />
            </div>
            <div className="thumbnails-container">
                {Array.isArray(images) &&
                    images.map((image: any, index: any) => {
                        if (image) {
                            return (
                                <img
                                    key={index}
                                    src={url + image}
                                    alt={`Thumbnail ${index + 1}`}
                                    className="thumbnail"
                                    onClick={() => handleThumbnailClick(image)}
                                />
                            );
                        }
                        return null;
                    })}
            </div>
            {modalOpen && (
                <div className="modal" onClick={closeModal}>
                    <img
                        src={url + selectedImage}
                        alt="Modal"
                        className="modal-image"
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            )}
        </div>
    );
};

export default Gallery;