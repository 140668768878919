
import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import { CardImage, CardTitle, Center, Container, ContainerCard, ContentCard, GridContainer, Title } from "./style";
import Navbar from "../../components/navbar/navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useServiceProperty } from "../../hooks/serviceProperty";
import { AppProperty } from "../../models/Property";
import { storageServerPath } from "../../utils/server/api";
import { useAuth } from "../../context/AuthContext";

export default function PropertiesEngineer() {
  const navigate = useNavigate();
  const typeUser = localStorage.getItem("userType");
  const { t } = useTranslation();
  const { getAreaEngineer } = useServiceProperty();

  const [properties, setProperties] = useState<AppProperty[]>([]);
  const { login } = useAuth();
  const token = localStorage.getItem("token");

  useEffect(() => {

    if (! token) {
        const initialValues = {
            email: "satelite@satelite.com",
            password: "satelite",
            user_type: "satelite",
        };

        login(initialValues);

        navigate("/news");
        return;
    } else {
        getAreaEngineer().then((data) => {
            setProperties(data);
        });
    }
  }, []);

  if (properties.length === 0) {
      return (
          <Container>
              <Center style={{marginTop: 30, marginBottom: 30}}>
                  <Title>{t("no-properties-found")}</Title>
              </Center>
          </Container>
      );
  }

  return (
      <Container>
          <GridContainer>
              {properties.map((p, i) => (
                  <ContainerCard
                      onClick={() =>
                          navigate(`/detailsPropertySeller/${p.id as number}`)
                      }
                      key={i}
                  >
                      <CardImage
                          src={storageServerPath + p?.file_images?.split(";")[0]}
                      />

                      <ContentCard>
                          <CardTitle>{p.name}</CardTitle>

                          <p>{(p.description || "").slice(0, 50)}</p>

                          <p>{p.cost_formatted}</p>
                      </ContentCard>
                  </ContainerCard>
              ))}
          </GridContainer>
      </Container>
  );
}
