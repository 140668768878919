import styled from "styled-components";

export const ContainerNoItems = styled.div`
  display: flex;
  width: 1100px;
  height: 500px;
  align-items: center;
  justify-content: center;
  border: solid 1px #EDE2E2;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 400px;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 30px;
  color:#50700F ;
  margin-left: 35px;
`;