import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Content = styled.div`
  flex-direction: row;
  display: flex;
  height: auto;
`;

export const SelectDrop = styled(Select)`
  width: 578px;
  height: 50px;
`;

export const Space = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 90vh;
`;

export const Img = styled.img`
  width: 30%;
  margin-left: 5px;

  @media only screen and (min-width: 768px) {
    margin-top: 1rem;
    width: 100%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (min-width: 1024px) {
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1440px) {
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1441px) {
    width: 100%; /* A largura será 100% do contêiner pai */
  }
`;

export const ButtonImg = styled.button`
  font-family: "poppins";
  color: #fff;
  font-size: 18px;
  margin-top: 15px;
  background-color: #2ca240;
  border-radius: 8px;
  height: 60px;
  left: 0;
  top: 0;
  width: 550px;
  border: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;

  @media only screen and (max-width: 480px) {
    height: 50px;
    width: 80%;
  }

  @media only screen and (min-width: 768px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    height: 10%;
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1441px) {
    height: 7%;
    width: 90%;
  }
`;

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  height: 1200px;
`;

export const Icon = styled.span<{ iconSize?: string }>`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: ${({ iconSize }) => iconSize || "16px"};
`;

export const LinhaVertical = styled.div`
  height: 80vh; /* Ajuste a altura da linha conforme necessário */
  width: 0.2px;
  background-color: #45B7FE; /* Cor da linha */
  margin-top: 70px;
  border-radius: 4px;
`;

export const Title = styled.h1`
  font-family: "poppins";
  color: #000000;
  font-size: 25px;
  margin-top: 90px;
  margin-bottom: 20px;
`;

export const TitleInput = styled.h1`
  font-family: "poppins";
  color: #000000;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  
`;

export const ButtonEnter = styled.button`
  background-color: #45B7FE;
  color: #ffff;
  font-size: 20px;
  border-radius: 10px;
  margin-left: 25%;
  height: 45px;
  width: 350px;
  margin-bottom: 20px;
`;

export const ButtonHome = styled.button`
  background-color: #45B7FE;
  color: #ffff;
  font-size: 20px;
  border-radius: 10px;
  height: 45px;
  width: 350px;
  border: 0;
  margin-bottom: 200px;
  margin-top: -300px;
`;

export const TitleButtonEnter = styled.h1`
  font-family: "poppins";
  color: #fff;
  font-size: 18px;
`;
