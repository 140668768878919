import axios, { AxiosRequestConfig } from "axios";
import { useAuth } from "../context/AuthContext";
import {
    UserUpdateResponse,
    BankDataUserUpdateResponse,
} from "../models/UserResponse";
import { apiBase } from "../utils/server/api";
import { IUser } from "../models/User";

export type PartialUserUpdate = Partial<{
  fullname: string;
  phone: string;
  company_name: string;
  state_registration: string;
  password: string;
  address: string;
  lat: any;
  lng: any;
}>;

export type PartialBankDataUpdate = Partial<{
    pix_key: string;
    pix: string;
    agency: string;
    agency_prefix: string;
    account: string;
    bank: string;
    document: string;
    favored: string;
}>;

const updateUser = (config: ReturnType<typeof useAuth>['axiosConfig']) =>
  async (data: PartialUserUpdate) => {
  try {
    const response = await axios.put(`${apiBase}/user`, data, config());
    return (response.data as UserUpdateResponse);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateDataBankUser =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: PartialBankDataUpdate) => {
        try {
            const response = await axios.post(
                `${apiBase}/user/bank-data`,
                data,
                config()
            );
            return response.data as BankDataUserUpdateResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const getUser =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async () => {
        try {
            const response = await axios.get(
                `${apiBase}/user`,
                config()
            );
            return response.data as UserUpdateResponse;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const recoverPassword =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async (data: {password: string, code?: string}) => {
        await axios.post(
            `${apiBase}/recover-password`,
            data,
            config()
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        })
    };

export function useServiceUser() {
  const { axiosConfig } = useAuth();
  return {
      updateUser: updateUser(axiosConfig),
      updateDataBankUser: updateDataBankUser(axiosConfig),
      getUser: getUser(axiosConfig),
      recoverPassword: recoverPassword(axiosConfig),
  };
}
