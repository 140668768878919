import Slider from "react-slick";
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
`;

export const StyledSlider = styled(Slider)`
    .slick-slide {
        img {
            width: 100%;
            height: 100%;
            max-height: 400px;
            object-fit: cover;
        }
    }

    .slick-dots {
        position: relative !important;
        bottom: 0 !important;
    }
`;

export const SlideImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
`;

export const ArrowLeft = styled.div`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
`;

export const ArrowRight = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
`;

export const Img = styled.img`
    width: 30px;
    height: 30px;
`;
