import styled from "styled-components";

export const Posts = styled.div`
  display: flex;
  margin-left: 8%;

  & > *:not(:last-child) {
    margin-right: 30%; 
    margin-bottom: 60px;
  }
`;

export const PostsSecond = styled.div`
  margin-left: 8%;

  & > *:not(:last-child) {
    margin-bottom: 60px;
  }
`;