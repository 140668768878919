import { BsTreeFill } from "react-icons/bs";
import { Container, Text, Title, Content, Date,Date2 } from "./style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MoreCard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container onClick={() => navigate("/news")}>
      <Content>
        <Date>
          <BsTreeFill size={50} color="#50700F" />
        </Date>
        <Date2>
          <Title>{t("warning-footer")}</Title>
          <Text >+{t("view-more")}</Text>
        </Date2>
      </Content>
    </Container>
  );
}
