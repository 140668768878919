import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const getAuthToken = () => {
    return localStorage.getItem("authToken");
};

const client = new ApolloClient({
    link: new HttpLink({
        uri: "https://plataforma.alerta.mapbiomas.org/api/v2/graphql",
        headers: {
            Authorization: `Bearer ${getAuthToken()}`,
        },
    }),
    cache: new InMemoryCache(),
});

export default client;
