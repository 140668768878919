import React from "react";
import { Route, Outlet } from "react-router-dom";

import { Navigate } from "react-router-dom";

const PrivateRoute = (props: any) => {
  const token = localStorage.getItem("token");
  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
