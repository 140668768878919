import styled from "styled-components";

 
export const InputAdress = styled.input`
box-sizing: border-box;
border: 1px solid #ccc;
width: 100%;
height: 50px;
padding: 0 35px;
border-radius: 10px;
font-size: 16px;
outline: none;
margin-bottom: 5px;
text-overflow: ellipses;
&::placeholder{
  color: #50700F;
}
`;