import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ContainerCard = styled.div`
  margin: 30px;
  display: flex;
`;

export const ContentCard = styled.div`
  margin-right: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const GridContainer = styled.div`
  margin-left: 30px;
  min-height: 56.8vh;
  border: 1px solid #EDE2E2;
  border-radius: 15px;
  margin-bottom: 50px;
`;

export const LineHorizontal = styled.div`
  background-color:#EDE2E2;
  height:1px;
  width:95%;
  margin-top:30px;
  margin-left: 40px;
`;
export const Title = styled.h1`
  display: flex;
  color: #50700F;
  font-size: 30px;
  justify-content: center;
  margin-top:230px ;
`;
