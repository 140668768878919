import { ContainerTitle, Title } from "./style";
import { IoNewspaper } from "react-icons/io5";


interface TitleProps {
  icon: React.ComponentType<any>;
  text: string;
}

export default function TitleComponent(props: TitleProps) {
  const { text } = props;
  return (
    <ContainerTitle>
      <IoNewspaper color="#50700F" size={35}/>

      <Title>{text}</Title>
    </ContainerTitle>
  );
}
