import React, { useEffect, useState } from 'react';
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import TitleComponent from "../../components/title/title";
import { IoMegaphoneOutline } from "react-icons/io5";
import Post from "../../components/post/post";
import { PostData } from "../../components/post/post"; 
import PostSecond from "../../components/post/postSecond";
import {Posts, PostsSecond } from "./style";
import { useTranslation } from 'react-i18next';
import { useServiceHome } from '../../hooks/serviceHome';

export default function Pronouncement() {
  const { t } = useTranslation();
  const { getPronunciamentos } = useServiceHome();
  const [posts, setPosts] = useState<PostData[]>([]);

  useEffect(() => {
    async function getPosts() {
      await getPronunciamentos()
        .then((response) =>{
          setPosts(response?.data);
        })
        .catch((error) => {
          console.log(error);
        })
    }

    getPosts();
  }, []);

  return (
      <>
          <NavBar />
          <TitleComponent
              icon={IoMegaphoneOutline}
              text={t("Pronouncements")}
          />
          <PostsSecond style={{marginBottom: '100px'}}>
              {posts.map((post) => (
                  <PostSecond key={post.id} data={post} />
              ))}
          </PostsSecond>
          <Footer />
      </>
  );
}