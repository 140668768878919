import React from "react";
import { Container, Content,ContentTwo,IconsContainer, TextSlogan,Text, VerticalLine } from "./style";
import { LuBarChart4 } from "react-icons/lu";
import { IoIosRocket } from "react-icons/io";
import { FaCreditCard } from "react-icons/fa6";
import googlePlay from "../../assets/images/googlePlay.png";
import appStore from "../../assets/images/appStore.png";
import { BsQrCode } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function Flys() {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <LuBarChart4 color="#50700F" size={35} />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("security-description")}</Text>
        </div>
      </Content>
      <VerticalLine />
      <Content>
      <FaCreditCard size={35} color="#50700F" />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("credit-card-description")}</Text>
        </div>
      </Content>
      <VerticalLine />
      <ContentTwo>
      <div>
        <TextSlogan> {t("Standing forest,life in balance")}</TextSlogan>
      </div>
      <IconsContainer>
        <img src={googlePlay} style={{ width: "180px" }} alt="logo googlePlay" />
        <img src={appStore} style={{ width: "180px" }} alt="logo appStore" />
      </IconsContainer>
    </ContentTwo>
      <VerticalLine />
      <Content>
        <BsQrCode size={35} color="#50700F" />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("Oversight real time")}</Text>
        </div>
      </Content>
      <VerticalLine />
      <Content>
        <IoIosRocket color="#50700F" size={35} />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("speed-description")}</Text>
        </div>
      </Content>
    </Container>
  );
}
