import Navbar from "../../../components/navbar/navbar";
import Partners from "../../../components/partners/partners";
import Pronouncement from "../../../components/pronouncement/pronouncement";
import {
    ContainerCard,
    Title,
    ContainerTitle,
    Container,
    GridContainer,
    CardImage,
    ContentCard,
    CardTitle,
    Content,
    Datecard,
    Description,
} from "./style";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Sliders from "../../../components/slider/slider";
import Flys from "../../../components/flys/flys";
import { useTranslation } from "react-i18next";
import { AppProperty } from "../../../models/Property";
import { storageServerPath } from "../../../utils/server/api";
import { useAuth } from "../../../context/AuthContext";
import { useServiceProperty } from "../../../hooks/serviceProperty";
import MoreCard from "../../../components/more-card/more-card";
import { useServiceHome } from "../../../hooks/serviceHome";
import MoreCardCatalog from "../../../components/more-card-catalog/more-card-catalog";

const HomeBuy: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { getHome } = useServiceHome();
    const { login } = useAuth();
    const token = localStorage.getItem("token");
    const typeUser = localStorage.getItem("userType");
    const { getArea, openGetArea, getCatalog } = useServiceProperty();
    const [imagePaths, setImagePaths] = useState<{
        carousel: { id: number; image_path: string }[];
        advert: {
            id: number;
            company_name: string;
            description: string;
            image: string;
            url: string;
            status: string;
        }[];
        pronouncement: {
            id: number;
            title: string;
            link: string;
            image: string;
            created_at: string;
        }[];
    }>({ carousel: [], advert: [], pronouncement: [] });

    useEffect(() => {
        getHome()
            .then((response) => {
                setImagePaths(response);
            })
            .catch((error) => {
                console.error(error);
            });

        if (!token) {
            const initialValues = {
                email: "satelite@satelite.com",
                password: "satelite",
                user_type: "satelite",
            };

            login(initialValues);

            navigate("/news");
            return;
        } else {
            if (typeUser === "engineer") {
                getArea().then((data) => {
                    setProperties(data);
                });
            } else {
                openGetArea().then((data) => {
                    setProperties(data);
                });
            }

            getCatalog().then((data) => {
                setCatalogs(data);
            });
        }
    }, []);

    const [properties, setProperties] = useState<AppProperty[]>([]);
    const [catalogs, setCatalogs] = useState<AppProperty[]>([]);
    const lng = localStorage.getItem("language");

    return (
        <Container>
            <Navbar />
            <Sliders images={imagePaths?.carousel} />
            <Flys />

            <ContainerTitle>
                <Title>{t("All properties")}</Title>
            </ContainerTitle>
            <Content>
                <GridContainer>
                    {properties.slice(0, 9).map((p, i) => (
                        <ContainerCard
                            onClick={() =>
                                navigate(
                                    `/detailsPropertySeller/${p.id as number}`
                                )
                            }
                            key={i}
                        >
                            <Datecard>
                                <CardImage
                                    src={
                                        storageServerPath +
                                        p?.file_images?.split(";")[0]
                                    }
                                />

                                <ContentCard>
                                    <CardTitle>
                                        {lng === "en"
                                            ? (p.name_en || "").slice(0, 57)
                                            : p.name.slice(0, 57)}
                                    </CardTitle>

                                    <Description>
                                        {lng === "en"
                                            ? (p.description_en || "").slice(
                                                  0,
                                                  80
                                              )
                                            : (p.description || "").slice(
                                                  0,
                                                  80
                                              )}
                                        ...
                                    </Description>

                                    <p>{p.cost_formatted}</p>
                                </ContentCard>
                            </Datecard>
                        </ContainerCard>
                    ))}
                </GridContainer>
                <MoreCard />
            </Content>
            <ContainerTitle>
                <Title>{t("catalog")}</Title>
            </ContainerTitle>
            <Content>
                <GridContainer>
                    {catalogs.slice(0, 9).map((p, i) => (
                        <ContainerCard
                            onClick={() =>
                                navigate(
                                    `/detailsPropertySeller/${p.id as number}`
                                )
                            }
                            key={i}
                        >
                            <Datecard>
                                <CardImage
                                    src={
                                        storageServerPath +
                                        p?.file_images?.split(";")[0]
                                    }
                                />

                                <ContentCard>
                                    <CardTitle>
                                        {lng === "en"
                                            ? (p.name_en || "").slice(0, 57)
                                            : p.name.slice(0, 57)}
                                    </CardTitle>

                                    <Description>
                                        {lng === "en"
                                            ? (p.description_en || "").slice(
                                                  0,
                                                  80
                                              )
                                            : (p.description || "").slice(
                                                  0,
                                                  80
                                              )}
                                        ...
                                    </Description>

                                    <p>{p.cost_formatted}</p>
                                </ContentCard>
                            </Datecard>
                        </ContainerCard>
                    ))}
                </GridContainer>
                <MoreCardCatalog />
            </Content>
            <Partners data={imagePaths?.advert} />
            <Pronouncement data={imagePaths?.pronouncement} />
            <Footer />
        </Container>
    );
};

export default HomeBuy;
