import styled from "styled-components";

export const InputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const InputField = styled.input`
    padding-right: 40px;

    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding-left: 35px; /* Ajuste o valor conforme necessário para dar espaço ao ícone */
    width: 578px;
    height: 50px;

    @media only screen and (max-width: 480px) {
        width: 100%;
    }

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

    &::placeholder {
        color: #50700f;
    }

    &:focus {
        outline: none;
    }
`;

export const IconContainer = styled.div`
    position: absolute;
    right: 10px;
    cursor: pointer;
`;
