import styled from "styled-components";

export const InputMap = styled.input`
    box-sizing: "border-box";
    border: 1px solid #000;
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    margin-bottom: 10px;
    text-overflow: ellipses;
`;

export const ButtonMap = styled.button`
    box-sizing: border-box;
    border: 1px solid #000;
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
`;