import styled from "styled-components";
import colors from "../../utils/colors";

export const CardContainer = styled.div`
  width: 400px; 
  height: 480px; /* altura maxima */
  cursor: pointer;
  position: relative;
  border: 1px solid #d1c7c7;
  border-radius: 15px;
  padding: 25px;
  
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const CardImage = styled.img`
  width: 350px;
  height: 230px;
  object-fit: cover;
  border-radius: 10px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 87%;
`;

export const Title = styled.h3`
  font-size: 22px;
  margin-bottom: 5px;
  font-family: "poppins";
  color: #50700F;
`;

export const AmountCarbon = styled.p`
  font-size: 16px;
  color: black;
  margin-bottom: 16px;
  font-family: "poppins";
  white-space: normal;
`;
export const Price = styled.p`
  font-size: 16px;
  color: black;
  margin-bottom: 16px;
  font-family: "poppins";
  white-space: normal;
`;

export const GreenBox = styled.div`
  background-color: ${colors.primary};
  color: #fff;
  padding: 8px;
  display: inline-block;
  margin-left: auto;
  border-radius: 4px;
  font-family: "poppins";
`;

export const Favorite = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleStatus = styled.h1`
  font-family: "poppins";
  color: ${colors.primary};
  font-size: 12px;
  text-transform: uppercase;
`;
