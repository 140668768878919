import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import { Container, ContainerCard, ContentCard, GridContainer } from "./style";
import Navbar from "../../components/navbar/navbar";
import Card from "../../components/card/card";
import casa from "../../../src/assets/images/casa.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseBuyer } from "../../utils/server/api";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
  },
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin: "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};


export default function AllProducts() {
  const navigate = useNavigate();
  const [property, setProperty] = useState([]);
  const typeUser = localStorage.getItem("userType");
  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    if (typeUser === "vendedor" && token) {
      navigate(`/detailsPropertySeller${queryString}`);
    } else {
      navigate(`/detailsPropertyBuy${queryString}`);
    }
  };

  const handleProperty = async () => {
    const response = await axios.get(`${baseBuyer}/product`, config);
    setProperty(response.data);

  };

  useEffect(() => {
    handleProperty();

  }, []);
  return (
    <Container>
      <Navbar />

      <TitleComponent icon={CiHome} text="Todas as propriedades" />
      <GridContainer>
        {property.map((item: any, index) => (
          <ContentCard key={index}>
            <Card
              image={item?.image}
              title={item?.name}
              description={item?.description}
              hectares={item?.hectares_amount}
              price={item.price}
              onClick={() => handleDetails(item)}
            />
          </ContentCard>
        ))}
      </GridContainer>
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
