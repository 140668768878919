import NavBar from "../../components/navbar/navbar";
import {
  Buttons,
  Container,
  ContainerInputs,
  InputContainer,
  MarginBottom,
  ProfilePic,
  TitleInput,
} from "./style";
import profile from "../../assets/images/new.png";
import InputWithIcon from "../../components/input/input";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { CiPhone } from "react-icons/ci";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { MdCorporateFare } from "react-icons/md";
import { FaUserCircle, FaWpforms } from "react-icons/fa";
import { Button } from "../../components/button/button";
import { useAuth } from "../../context/AuthContext";
import {
  Formik,
  Field,
  Form,
  FieldInputProps,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import axios from "axios";
import { baseBuyer, baseSeller } from "../../utils/server/api";
import { useEffect, useState } from "react";
import { Center } from "@chakra-ui/react";
import { Data } from "@react-google-maps/api";
import { useMeData } from "../../hooks/serviceMe";
import { log } from "console";
import { useNavigate } from "react-router-dom";

export default function Settings() {
  const { data, isLoading } = useMeData();
  const type = localStorage.getItem("userType");
  const navigate = useNavigate();
  const { register, registerError } = useAuth();

  useEffect(() => {

  }, []);

  const initialValues = {
    email: data?.email || "",
    name: data?.name || "",
    business_name: data?.business_name || "",
    corporate_name: data?.corporate_name || "",
    surname: data?.surname || "",
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
      },
    };

    const data = {
      email: values.email,
      name: values.name,
      surname: values.surname,
      business_name: values.business_name,
      corporate_name: values.corporate_name,
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    };
    try {

      if (type === "vendedor") {
        await axios.put(`${baseSeller}/me`, data, config);

        navigate("/profile");
      }
      await axios.put(`${baseBuyer}/me`, data, config);

      navigate("/profile");
    } catch (error) {
      console.error("Erro ao editar:", (error as Error).message);
    }
  };
  return (
    <div>
      <NavBar />
      <Container>
        <FaUserCircle size={100} />
        <MarginBottom />
        <MarginBottom />
        {isLoading && <Center>Carregando...</Center>}
        {!isLoading && (
          <>
            {" "}
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                {type === "vendedor" && (
                  <>
                    <TitleInput>Nome</TitleInput>
                    <Field
                      type="text"
                      name={
                        data.corporate_name ? "corporate_name" : "business_name"
                      }
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <InputWithIcon
                          icon={<HiOutlineUserCircle />}
                          iconSize="23px"
                          field={field}
                        />
                      )}
                    />
                    <MarginBottom />
                  </>
                )}

                {type === "cliente" && (
                  <>
                    <TitleInput>Nome</TitleInput>
                    <Field
                      type="text"
                      name={data.corporate_name ? "corporate_name" : "name"}
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <InputWithIcon
                          icon={<HiOutlineUserCircle />}
                          iconSize="23px"
                          field={field}
                        />
                      )}
                    />
                    <MarginBottom />
                  </>
                )}

                {type === "cliente" && data.corporate_name === "" && (
                  <>
                    <TitleInput>Sobrenome</TitleInput>
                    <Field
                      type="text"
                      name="surname"
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <InputWithIcon
                          icon={<HiOutlineUserCircle />}
                          iconSize="23px"
                          field={field}
                        />
                      )}
                    />
                    <MarginBottom />
                  </>
                )}

                <TitleInput>E-mail</TitleInput>
                <Field
                  type="text"
                  name="email"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<MdOutlineEmail />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />
                <MarginBottom />

                <TitleInput>Senha antiga</TitleInput>
                <Field
                  type="password"
                  name="old_password"
                  render={({ field }: { field: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<RiLockPasswordLine />}
                      iconSize="23px"
                      field={field}
                      type="password"
                    />
                  )}
                />
                <MarginBottom />

                <TitleInput>Nova Senha</TitleInput>
                <Field
                  type="password"
                  name="new_password"
                  render={({ field }: { field: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<RiLockPasswordLine />}
                      iconSize="23px"
                      field={field}
                      type="password"
                    />
                  )}
                />
                <MarginBottom />

                <TitleInput>Confirme sua nova senha</TitleInput>
                <Field
                  type="password"
                  name="confirm_password"
                  render={({ field }: { field: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<RiLockPasswordLine />}
                      iconSize="23px"
                      field={field}
                      type="password"
                    />
                  )}
                />
                <MarginBottom />
                <Center>
                  <Button
                    colorButton="#48C85D"
                    colorText="white"
                    title="Atualizar"
                  />
                </Center>
              </Form>
            </Formik>
          </>
        )}

        {/* <ContainerInputs>
          <InputContainer>
            <TitleInput>Nome</TitleInput>
            <InputWithIcon icon={HiOutlineUserCircle} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Email</TitleInput>
            <InputWithIcon icon={MdOutlineEmail} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>CPF</TitleInput>
            <InputWithIcon icon={FaWpforms} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Telefone</TitleInput>
            <InputWithIcon icon={CiPhone} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Senha Antiga</TitleInput>
            <InputWithIcon icon={RiLockPasswordLine} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Senha Nova</TitleInput>
            <InputWithIcon icon={RiLockPasswordLine} iconSize="23px" />
          </InputContainer>
          <InputContainer>
            <TitleInput>Confirme nova senha</TitleInput>
            <InputWithIcon icon={RiLockPasswordLine} iconSize="23px" />
          </InputContainer>
        </ContainerInputs> */}
        {/* <Buttons>
          <Button colorButton="#48C85D" colorText="white" title="Atualizar" />
          <div style={{ marginLeft: "10px" }} />
          <Button
            colorButton="#EE7575"
            colorText="white"
            title="Excluir Conta"
          />
        </Buttons> */}
      </Container>
    </div>
  );
}
