import {
  ButtonR,
  Title,
  ButtonOut,
  TitleOutline,
  Icon,
  IconsContainer,
} from "./style";
import { IconType } from "react-icons";
import React from "react";
import { BeatLoader } from "react-spinners";

interface ButtonsProps {
  title: string;
  colorButton?: any;
  colorText?: any;
  onClick?: () => void;
  isLoading?: boolean;
}

interface ButtonOutlineProps {
  title: string;
  colorButton?: any;
  colorText?: any;
  onClick?: () => void;
  icon: React.ComponentType<any>;
  iconSize?: string;
  isLoading?: boolean;
}

interface ButtonOutlineNoIconProps {
  title: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export function Button(props: ButtonsProps) {
  const { title, colorButton, colorText, onClick, isLoading } = props;
  return (
    <>
      <ButtonR colorButton={colorButton} onClick={onClick}>
        {isLoading ? (
          <BeatLoader className="spinner" size={8} color={"#fff"} />
        ) : (
          <Title colorText={colorText}>{title}</Title>
        )}
      </ButtonR>
    </>
  );
}

export function ButtonOutline(props: ButtonOutlineProps) {
  const { title, icon: IconComponent, iconSize, onClick } = props;
  return (
    <>
      <ButtonOut onClick={onClick}>
        <IconsContainer>
          <Icon iconSize={iconSize}>
            <IconComponent />
          </Icon>
          <TitleOutline>{title}</TitleOutline>
        </IconsContainer>
      </ButtonOut>
    </>
  );
}

export function ButtonOutlineNoIcon(props: ButtonOutlineNoIconProps) {
  const { title, onClick } = props;
  return (
    <>
      <ButtonOut onClick={onClick}>
        <TitleOutline>{title}</TitleOutline>
      </ButtonOut>
    </>
  );
}
