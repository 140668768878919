import { Route, Routes, Navigate, Router } from "react-router-dom";

import Choice from "../screens/choice/choice";
import LoginBuy from "../screens/login/login-buy/login-buy";
import LoginSeller from "../screens/login/login-seller/login-seller";
import RegisterBuy from "../screens/register/register-buy/register-buy";
import LoginEngineer from "../screens/login/login-engineer/login-engineer";
import RegisterEngineer from "../screens/register/register-engineer/register-engineer";
import RegisterSeller from "../screens/register/register-seller/register-seller";
import HomeBuy from "../screens/home/home-buy/home-buy";
import HomeSeller from "../screens/home/home-seller/home-seller";
import DetailsPropertyBuy from "../screens/details-property/buy/details-property";
import DetailsPropertySeller from "../screens/details-property/seller/details-property";
import Cart from "../screens/cart/cart";
import ProfileSeller from "../screens/profile/profile-seller/profile-seller";
import News from "../screens/news/news";
import Contact from "../screens/contatos/contatos";
import Pronouncement from "../screens/pronouncement/pronouncement";
import Favorites from "../screens/favorites/favorites";
import Settings from "../screens/settings/settings";
import Search from "../screens/search/search";
import MySpaceBuy from "../screens/my-space/buy/my-space";
import MySpaceSeller from "../screens/my-space/seller/my-space";
import RegisterProperty from "../screens/register-property/register-property";
import PrivateRoute from "./PrivateRoute";
import CreditCardPage from "../screens/credit-card/credit-card";
import ForgetPassword from "../screens/forget-password/forget-password";
import AllProducts from "../screens/allProducts/all-products";
import HomeAll from "../screens/home/home-all/home-all";
import UpdateProperty from "../screens/update-property/update-property";
import Order from "../screens/order/order";
import CreditCardPayment from "../screens/order/components/screens/credit-card/credit-card";
import Pix from "../screens/order/components/screens/pix/pix";
import Ticket from "../screens/order/components/screens/ticket/ticket";
import MySpaceBankRegister from "../screens/my-space/buy/my-space-bank-card";
import Satelite from "../screens/satelite/satelite";
import HomeRouter from "../screens/router/HomeRouter";
import MySpaceRouter from "../screens/router/MySpaceRouter";
import MySpaceEngineer from "../screens/my-space/engineer/my-space";
import PaySuccess from "../screens/pay-success/PaySuccess";
import PayError from "../screens/pay-success/PayError";
import RecoverPassword from "../screens/recover-password/recoverPassword";
import Catalog from "../screens/catalog/catalog";
import MapBiomas from "../screens/map-biomas/map-biomas";

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomeBuy />} />
            <Route path="/loginBuy" element={<LoginBuy />} />
            <Route path="/registerBuy" element={<RegisterBuy />} />
            <Route path="/loginSeller" element={<LoginSeller />} />
            <Route path="/registerSeller" element={<RegisterSeller />} />
            <Route path="/loginEngineer" element={<LoginEngineer />} />
            <Route path="/registerEngineer" element={<RegisterEngineer />} />
            <Route path="/choice" element={<Choice />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/news" element={<News />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pronouncement" element={<Pronouncement />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/search" element={<Search />} />
            <Route path="/recoverPassword" element={<RecoverPassword />} />
            <Route
                path="/DetailsPropertyBuy"
                element={<DetailsPropertyBuy />}
            />
            <Route path="/allProducts" element={<AllProducts />} />

            <Route path="/" element={<PrivateRoute />}>
                <Route path="/home" element={<HomeRouter />} />
                <Route path="/mySpace" element={<MySpaceRouter />} />

                <Route path="/homeBuy" element={<HomeBuy />} />

                <Route path="/homeSeller" element={<HomeSeller />} />

                <Route path="/mySpaceBuy" element={<MySpaceBuy />} />
                <Route path="/mySpaceBuy/:subpage" element={<MySpaceBuy />} />
                <Route
                    path="/registerCreditCard"
                    element={<MySpaceBankRegister />}
                />
                <Route
                    path="/DetailsPropertyBuy/:id"
                    element={<DetailsPropertyBuy />}
                />

                <Route path="/mySpaceSeller" element={<MySpaceSeller />} />

                <Route
                    path="/mySpaceSeller/:subpage"
                    element={<MySpaceSeller />}
                />

                <Route path="/mySpaceEngineer" element={<MySpaceEngineer />} />

                <Route
                    path="/mySpaceEngineer/:subpage"
                    element={<MySpaceEngineer />}
                />

                <Route
                    path="/detailsPropertySeller/:id"
                    element={<DetailsPropertySeller />}
                />
                <Route path="/cart" element={<Cart />} />
                <Route path="/profileSeller" element={<ProfileSeller />} />
                <Route path="/settings" element={<Settings />} />
                <Route
                    path="/registerProperty"
                    element={<RegisterProperty />}
                />
                <Route
                    path="/updateProperty/:id"
                    element={<UpdateProperty />}
                />
                <Route path="/creditCard" element={<CreditCardPage />} />
                <Route path="/satelite" element={<Satelite />} />
                <Route path="/mapBiomas" element={<MapBiomas />} />

                <Route path="/order" element={<Order />} />
                <Route
                    path="/CreditCardPayment"
                    element={<CreditCardPayment />}
                />
                <Route path="/Pix" element={<Pix />} />
                <Route path="/Ticket" element={<Ticket />} />
                <Route path="/PaySuccess" element={<PaySuccess />} />
                <Route path="/PayError" element={<PayError />} />
            </Route>
        </Routes>
    );
}
