import styled from "styled-components";
import colors from "../../../utils/colors";

export const Container = styled.div`
  width: 100%;
`;

export const Main = styled.div`
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  display: grid;
  grid-template-areas:
    "banners header"
    "meta meta"
    "content content"
    ;
  grid-gap: 20px;
  grid-template-columns: auto 1fr;
`;

export const BannerContainer = styled.div`
  grid-area: banners;
`;

export const HeaderContainer = styled.div`
  grid-area: header;
`;

export const MetaContainer = styled.div`
  grid-area: meta;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
`;

export const ContentContainer = styled.div`
  grid-area: content;

  border-bottom-left: 1px solid ${colors.gray};
  border-bottom-right: 1px solid ${colors.gray};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  & > * {
    padding: 15px 40px;
  }

  & > h1 {
    background-color: ${colors.primary};
  }

  & > div {
    p {
      margin-top: 40px;
      color: ${colors.text};
    }
  }
`;

export const BannerImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;

  img {
    border-radius: 10px;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    & > img {
      width: auto;
      height: 90px;
      flex: 1;
    }
    height: 50%;
  }

  & > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 490px;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  color: ${colors.text};
`;

export const HeaderTable = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  grid-gap: 20px;

  & > div {
    font-size: 22px;
    display: flex;
    flex-direction: row;

    strong {
      flex: 0;
      min-width: 150px;
    }

    p {
      flex: 1;
      color: ${colors.text};
    }
  }
`;

export const MetaItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  background-color: ${colors.primary};
  cursor: pointer;

  & > img {
    width: auto;
    height: 30px;
    margin-right: 10px;
  }

  & > p {
    color: ${colors.text};
  }
`;
