import styled from "styled-components";

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5%;
    align-items: center;
    background-color: #45b7fe;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
        height: auto;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    max-width: 860px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
        height: 480px;
        margin-bottom: 0;
    }
`;

export const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    @media (min-width: 768px) {
        width: 50%;
        padding: 40px 10px 40px 40px;
    }
`;

export const ContainerImg = styled.div`
    width: 100%;
    height: 240px;

    @media (min-width: 768px) {
        width: 50%;
        height: 100%;
    }
`;

export const Title = styled.h1`
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    color: #50700f;
    margin-bottom: 10px;

    @media (min-width: 768px) {
        font-size: 36px;
        padding-bottom: 10%;
    }
`;

export const Description = styled.p`
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #50700f;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        font-size: 18px;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 0 10px 10px;

    @media (min-width: 768px) {
        border-radius: 0 10px 10px 0;
    }
`;

export const ButtonView = styled.button`
    height: 50px;
    width: 100%;
    border-radius: 10px;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    background-color: #45b7fe;
    color: #fff;
    margin-top: auto;
    border: none;
    cursor: pointer;

    @media (min-width: 768px) {
        height: 60px;
        width: 260px;
        font-size: 20px;
    }
`;
