import styled from "styled-components";
import colors from "../../utils/colors";

interface ButtonsProps {
  colorButton?: any;
  colorText?: any;
}

export const ButtonR = styled.button<ButtonsProps>`
  background-color: ${(props) => props.colorButton || "#48c85d"};
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 350px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1<ButtonsProps>`
  font-family: "Josefin Bold";
  color: ${(props) => props.colorButton || "#fff"};
  font-size: 18px;
`;

export const ButtonOut = styled.button`
  background-color: #fff;
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 350px;
  margin-bottom: 20px;
  justify-content: center;
  border: 1px solid ${colors.primary};
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  justify-content: center;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const TitleOutline = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 18px;
  text-align: center;
`;

export const Icon = styled.span<{ iconSize?: string }>`
  font-size: ${({ iconSize }) => iconSize || "18px"};
  margin-top: 4px;
  text-align: center;
  color: #000;
  margin-right: 5px;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
