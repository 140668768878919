import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Center,
  Title,
  GridContainer,
  ContainerCard,
  CardImage,
  CardTitle,
  ContentCard,
} from './style';
import { storageServerPath } from "../../../utils/server/api";
import { useServiceShoppingCart } from "../../../hooks/serviceShoppingCart";

export default function MySpaceProperty() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getFinishedOrders } = useServiceShoppingCart();

  const [orders, setOrders] = useState<any[]>([]);

  useEffect(() => {
      getFinishedOrders().then((data) => {
        setOrders(data.data);
      });
  }, []);

  if (orders.length === 0) {
      return (
          <Container>
              <Center>
                  <Title>{t("empty-order-warning-1")}</Title>
                  <Title style={{ marginTop: -10 }}>
                      {t("empty-order-warning-2")}
                  </Title>
              </Center>
          </Container>
      );
  }

  return (
      <Container>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead style={{ textAlign: "left" }}>
                  <th
                      style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid black",
                      }}
                  >
                      {t("property")}
                  </th>
                  <th
                      style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid black",
                      }}
                  >
                      {t("order-status")}
                  </th>
                  <th
                      style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid black",
                      }}
                  >
                      {t("amount")}
                  </th>
                  <th
                      style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid black",
                      }}
                  >
                      {t("payments")}
                  </th>
                  <th
                      style={{
                          fontWeight: "bold",
                          borderBottom: "1px solid black",
                      }}
                  >
                      {t("updated")}
                  </th>
              </thead>
              <tbody>
                  {orders.map((p, index) => (
                      <tr
                          key={index}
                          style={{ borderBottom: "1px solid black" }}
                      >
                          <td>{p.items[0]["property"]?.name}</td>
                          <td>{t(p.status)}</td>
                          <td>{t(p.total)}</td>
                          <td>{t(p.payment_method)}</td>
                          <td>{p.updated_at}</td>
                      </tr>
                  ))}
              </tbody>
          </table>
      </Container>
  );
}
