import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  background-color: ${colors.primary};
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ListItem = styled.ul`
  margin-right: 10px;
  color: ${colors.text};
`;

export const Icon = styled.img<{
  tiny?: boolean,
}>`
  width: auto;
  max-height: ${props => props.tiny ? '20px' : '25px'};
`;
