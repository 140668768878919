import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    gap: 20px;
    font-size: 20px;
    font-family: "poppins";
    color: #50700F;
    margin-left: 2%;
`;

export const ButtonRemove = styled.div`
    display: flex;
    gap: 15px;
    cursor: pointer;
`;

export const DivRemove = styled.div`
    display: flex;
    margin-top: 135px;
    margin-left: 80px;
`;

export const Quantity = styled.p`
    border: 1px solid;
    text-align: center;
    align-content: center;
    font-size: 20px;
    border-radius: 15px;
    width: 100px;
    height: 70px;
    margin-left: 10px;
    margin-right: 10px;
`;

export const ButtonSelector = styled.button`
font-size: 40px;
`;