import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Container,
    StyledSlider,
    SlideImage,
    ArrowLeft,
    ArrowRight,
    Img,
} from "./style";
import arrow1 from "../../assets/images/arrow1.png";
import arrow2 from "../../assets/images/arrow2.png";
import { storageServerPath } from "../../utils/server/api";

interface Image {
    id: number;
    image_path: string;
}

interface SlidersProps {
    images: Image[];
}

const Sliders: React.FC<SlidersProps> = ({ images }) => {
    const sliderRef = useRef<Slider | null>(null);

    const uniqueImages = images.filter(
        (image, index, self) =>
            index === self.findIndex((img) => img.id === image.id)
    );

    const settings = {
        dots: true,
        infinite: uniqueImages.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: uniqueImages.length > 1,
        autoplaySpeed: 3000,
    };

    const next = () => {
        sliderRef.current?.slickNext();
    };

    const previous = () => {
        sliderRef.current?.slickPrev();
    };

    return (
        <Container>
            {uniqueImages.length > 1 && (
                <ArrowLeft onClick={previous}>
                    <Img src={arrow1} alt="Previous" />
                </ArrowLeft>
            )}
            <StyledSlider ref={sliderRef} {...settings}>
                {uniqueImages.map((image) => (
                    <div key={image.id}>
                        <SlideImage
                            src={storageServerPath + image.image_path}
                            alt={`Slide ${image.id}`}
                        />
                    </div>
                ))}
            </StyledSlider>
            {uniqueImages.length > 1 && (
                <ArrowRight onClick={next}>
                    <Img src={arrow2} alt="Next" />
                </ArrowRight>
            )}
        </Container>
    );
};

export default Sliders;
