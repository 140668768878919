import { useEffect, useState } from "react";
import "./modalConfirmation.css";
import { useTranslation } from "react-i18next";

function ModalConfirmation({
    show,
    alterOpenModalDelete,
    message,
}: {
    show: any;
    alterOpenModalDelete: any;
    message: string;
}) {
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setShowModal(show);
    }, [show]);

    const handleConfirm = () => {
        setShowModal(false);
        alterOpenModalDelete(false, true);
    };

    const handleCancel = () => {
        setShowModal(false);
        alterOpenModalDelete(false, false);
    };

    return (
        <div>
            {showModal && (
                <div className="modal-overlay">
                    {" "}
                    <div className="modal">
                        <div className="modal-content">
                            <p>{message}</p>
                            <button
                                className={"buttonConfirm"}
                                onClick={handleConfirm}
                            >
                                {t("button-confirm")}
                            </button>
                            <button onClick={handleCancel}>
                                {t("button-cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ModalConfirmation;
