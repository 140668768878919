import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { apiBase } from "../utils/server/api";

const getHome =
    (config: ReturnType<typeof useAuth>["headerConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/home-site`, config())
            .then((response: any) => {
                return response.data;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getPronunciamentos =
    (config: ReturnType<typeof useAuth>["headerConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/pronouncement`, config())
            .then((response: any) => {
                return response.data;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getVegetations =
    (config: ReturnType<typeof useAuth>["headerConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/vegetation`, config())
            .then((response: any) => {
                return response.data;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

export function useServiceHome() {
    const { axiosConfig } = useAuth();

    return {
        getHome: getHome(axiosConfig),
        getPronunciamentos: getPronunciamentos(axiosConfig),
        getVegetations: getVegetations(axiosConfig),
    };
}
