import {
    Container,
    Input,
    InputContainer,
    InputLabel,
    InputError,
    Button,
    Center,
} from "./style";

import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useServiceUser } from "../../../hooks/serviceUser";
import { toast, ToastContainer } from "react-toastify";
import MapLocalize from "../../../components/googleMaps/MapLocalize";
import { useState } from "react";

export default function MySpaceEngineerProfile() {
    const { user } = useAuth();
    const { t } = useTranslation();
    const { updateUser } = useServiceUser();
    const [addressComponent, setAddressComponent] = useState<any>();

    const getAddressComponent = (addressData: any) => {
        setAddressComponent(addressData);
    };

    if (!user) {
        return null;
    }

    const initialValues = {
        fullname: user?.fullname || "",
        email: user?.email || "",
        phone: user?.phone || "",
        document: user?.document || "",
        state_registration: user?.state_registration || "",
        password: "",
        new_password_confirm: "",
        address: addressComponent?.address || "",
        lat: addressComponent?.lat || "",
        lng: addressComponent?.lng || "",
    };

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        const data = {} as Parameters<typeof updateUser>[0];

        if (values.fullname.length > 0) {
            data.fullname = values.fullname;
        }

        if (values.phone.length > 0) {
            data.phone = values.phone;
        }

        if (values.state_registration.length > 0) {
            data.state_registration = values.state_registration;
        }

        if (values.password.length > 0) {
            data.password = values.password;
        }

        values.address = addressComponent?.formatted_address || user?.address;
        values.lat = addressComponent?.geometry.location.lat() || user?.lat;
        values.lng = addressComponent?.geometry.location.lng() || user?.lng;

        await updateUser(values)
            .then(() => {
                toast.success(t("successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
            .catch((error) => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    const errors = error.response.data.errors;

                    Object.keys(errors).forEach((field) => {
                        errors[field].forEach((errorMessage: string) => {
                            toast.error(t(errorMessage), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        });
                    });
                }
            });
    };

    const formValidation = (values: typeof initialValues) => {
        if (values.password.length > 0) {
            if (values.password !== values.new_password_confirm) {
                return {
                    new_password_confirm: t("password-not-equal"),
                };
            }
        }

        if (values.fullname.length === 0) {
            return {
                fullname: t("field-required"),
            };
        }

        return {};
    };

    return (
        <Container>
            <ToastContainer />
            <Formik
                validate={formValidation}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                <Form>
                    <InputContainer>
                        <div>
                            <InputLabel>{t("corporate-reason")}</InputLabel>
                            <Field as={Input} type="text" name="fullname" />
                            <InputError>
                                <ErrorMessage name="fullname" />
                            </InputError>

                            <InputLabel>{t("phone")}:</InputLabel>
                            <Field as={Input} type="text" name="phone" />
                            <InputError>
                                <ErrorMessage name="phone" />
                            </InputError>

                            <InputLabel>Cnpj/Cpf:</InputLabel>
                            <Field
                                as={Input}
                                type="text"
                                name="document"
                                disabled
                            />
                            <InputError>
                                <ErrorMessage name="document" />
                            </InputError>

                            <InputLabel hidden={user?.document_type === "cpf"}>
                                {t("state-registration")}:
                            </InputLabel>
                            <Field
                                as={Input}
                                type="text"
                                name="state_registration"
                                style={{
                                    display: `${
                                        user?.document_type === "cpf"
                                            ? "none"
                                            : "block-inline"
                                    }`,
                                }}
                            />
                            <InputError>
                                <ErrorMessage name="state_registration" />
                            </InputError>
                            <InputLabel>{t("address")}:</InputLabel>
                            <MapLocalize
                                getAddressComponent={getAddressComponent}
                                setAddress={user?.address}
                                setLat={parseFloat(user?.lat) || 0}
                                setLng={parseFloat(user?.lng || 0)}
                                view={false}
                            />
                        </div>

                        <div>
                            <InputLabel>{t("email")}</InputLabel>
                            <Field
                                as={Input}
                                type="email"
                                name="email"
                                disabled
                            />
                            <InputError>
                                <ErrorMessage name="email" />
                            </InputError>

                            {/* <InputLabel>{t("old-password")}:</InputLabel>
                            <Field
                                as={Input}
                                type="password"
                                name="old_password"
                            />
                            <InputError>
                                <ErrorMessage name="old_password" />
                            </InputError> */}

                            <InputLabel>{t("new-password")}:</InputLabel>
                            <Field
                                as={Input}
                                type="password"
                                name="password"
                            />
                            <InputError>
                                <ErrorMessage name="new_password" />
                            </InputError>

                            <InputLabel>
                                {t("confirm-new-password")}:
                            </InputLabel>
                            <Field
                                as={Input}
                                type="password"
                                name="new_password_confirm"
                            />
                            <InputError>
                                <ErrorMessage name="new_password_confirm" />
                            </InputError>
                        </div>
                    </InputContainer>

                    <Center>
                        <Button type="submit">{t("update")}</Button>
                    </Center>
                </Form>
            </Formik>
        </Container>
    );
}
