import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Field, Form, ErrorMessage, FieldInputProps } from "formik";
import * as Yup from "yup";
import Navbar from "../../components/navbar/navbar";
import { ContainerTitle, Title } from "../cart/style";
import { BsCart3, BsCreditCard2Front } from "react-icons/bs";
import { AiFillStar, AiOutlineDelete } from "react-icons/ai";
import Modal from "react-modal";
import MaskedInput from "../../components/input/inputMask";
import colors from "../../utils/colors";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaAddressCard, FaUserLarge } from "react-icons/fa6";
import InputWithIcon from "../../components/input/input";
import { MdCalendarMonth } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { SelectContainer } from "./style";
import { useMeData } from "../../hooks/serviceMe";

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.div`
  border: 1px solid #28a745; /* Cor verde para a borda */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Elevação */
  padding: 16px;
  margin: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  border-radius: 4px;
`;

const CardInfo = styled.div`
  margin-bottom: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-top: 8px;
`;

const StarIcon = styled(AiFillStar)`
  color: #f8d60e; /* Cor amarela para o ícone de estrela */
`;

const DeleteIcon = styled(AiOutlineDelete)`
  color: #dc3545; /* Cor vermelha para o ícone de delete */
`;

const MainButton = styled.button`
  background-color: ${colors.primary};
  color: #fff;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  font-family: "Josefin Bold";
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-top: 10px;
`;

const MainButtonModal = styled.button`
  background-color: ${colors.primary};
  color: #fff;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-family: "Josefin Bold";
  margin-top: 30px;
`;

const ModalWrapper = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  elevation: 5px;
  border-width: 1px;
  border-color: #28a745;
`;

const Label = styled.p`
  font-family: "Josefin Sans";
  font-size: 14px;
  color: ${colors.gray};
  margin-bottom: 2px;
  margin-top: 5px;
`;

const LabelCard = styled.p`
  font-family: "Josefin Sans";
  font-size: 14px;
  color: ${colors.gray};
  margin-bottom: 2px;
`;

const TitleCard = styled.h1`
  font-family: "Josefin Sans";
  font-size: 14px;
  color: #000;
  margin-bottom: 2px;
  font-size: 16px;
  margin-top: 10px;
`;

const TitlePCard = styled.h1`
  font-family: "Josefin Sans";
  font-size: 14px;
  color: red;
  margin-bottom: 2px;
  font-size: 16px;
  margin-top: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const initialValues = {
  number: "",
  expirationMonth: "",
  expirationYear: "",
  firtsName: "",
  lastName: "",
  cvv: "",
};

const CreditCardPage: React.FC = () => {
  const { data } = useMeData();
  const { t } = useTranslation();

  const [cards, setCards] = useState<
    {
      id: number;
      userId: number;
      number: string;
      expirationMonth: string;
      expirationYear: string;
      firtsName: string;
      lastName: string;
      cvv: string;
      isMain: boolean;
    }[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {

    const storedCards = JSON.parse(localStorage.getItem("creditCards") || "[]");
    const user = data?.id;
    const userCards = storedCards.filter((card: any) => card.userId === user);
    setCards(userCards);
  }, [data]);

  const updateLocalStorage = (updatedCards: any) => {
    localStorage.setItem("creditCards", JSON.stringify(updatedCards));
  };

  const handleSetMainCard = (cardId: number) => {
    const updatedCards = cards.map((card) => ({
      ...card,
      isMain: card.id === cardId,
    }));
    setCards(updatedCards);
    updateLocalStorage(updatedCards);
  };

  const handleRemoveCard = (cardId: number) => {
    const updatedCards = cards.filter((card) => card.id !== cardId);
    setCards(updatedCards);
    updateLocalStorage(updatedCards);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (values: any, { resetForm }: any) => {

    const newCard = {
      id: cards.length + 1,
      userId: data.id,
      number: values.number.replace(/\s/g, ""),
      expirationMonth: values.expirationMonth,
      expirationYear: values.expirationYear,
      firtsName: values.firtsName,
      lastName: values.lastName,
      cvv: values.cvv,
      brand: values.brand,
      cpf: values.cpf,
      isMain: false,
    };
    setCards([...cards, newCard]);
    updateLocalStorage([...cards, newCard]);
    resetForm();
    closeModal();

  };
  return (
    <div>
      <Navbar />
      <ContainerTitle>
        <BsCart3 size={25} />
        <Title>{t("my-credit-cards")}</Title>
      </ContainerTitle>
      <CardList>
        {cards.map((card) => (
          <Card key={card.id}>
            <LabelCard>Número do cartão</LabelCard>
            <LabelCard>{card.number}</LabelCard>
            <LabelCard>Titular</LabelCard>
            <LabelCard>{card.firtsName + " " + card.lastName}</LabelCard>
            <Row>
              {card.isMain ? (
                <button onClick={() => handleSetMainCard(card.id)}>
                  <FaHeart color="red" />
                </button>
              ) : (
                <button onClick={() => handleSetMainCard(card.id)}>
                  <TitleCard>Tornar Principal</TitleCard>
                </button>
              )}

              <TitlePCard onClick={() => handleRemoveCard(card.id)}>
                Remover
              </TitlePCard>
            </Row>
          </Card>
        ))}
      </CardList>
      <MainButton onClick={openModal}>{t("add-a-credit-card")}</MainButton>
      <ModalWrapper isOpen={isModalOpen} onRequestClose={closeModal}>
        <Title>{t("add-a-credit-card")}</Title>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <Label>{t("card-number")}:</Label>

            <Field
              type="text"
              name="number"
              render={({ field }: { field: FieldInputProps<any> }) => (
                <MaskedInput
                  field={field}
                  icon={<FaAddressCard />}
                  mask="9999 9999 9999 9999"
                  placeholder=""
                />
              )}
            />
            <Label>CVV:</Label>
            <Field
              type="text"
              name="cvv"
              render={({ field }: { field: FieldInputProps<any> }) => (
                <MaskedInput
                  field={field}
                  icon={<BsCreditCard2Front />}
                  mask="999"
                  placeholder=""
                />
              )}
            />

            <Label>CPF:</Label>
            <Field
              type="number"
              name="cpf"
              render={({ field }: { field: FieldInputProps<any> }) => (
                <MaskedInput
                  field={field}
                  icon={<BsCreditCard2Front />}
                  mask="999 999 999 99"
                  placeholder=""
                />
              )}
            />

            <Label> Bandeira</Label>
            <SelectContainer>
              <Field as="select" id="brand" name="brand">
                <option value="visa" label="Visa" />
                <option value="mastercard" label="MasterCard" />
                <option value="amex" label="American Express" />
                <option value="elo" label="Elo" />
                <option value="diners" label="Diners" />
              </Field>
            </SelectContainer>

            <Label>{t("first-name")}:</Label>
            <Field
              type="text"
              name="firtsName"
              render={({ field }: { field: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<FaUserLarge />}
                  iconSize="23px"
                  field={field}
                  type="text"
                />
              )}
            />
            <Label>{t("second-name")}:</Label>
            <Field
              type="text"
              name="lastName"
              render={({ field }: { field: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<FaUserLarge />}
                  iconSize="23px"
                  field={field}
                  type="text"
                />
              )}
            />
            <Label>{t("month")}:</Label>

            <Field
              type="text"
              name="expirationMonth"
              render={({ field }: { field: FieldInputProps<any> }) => (
                <MaskedInput
                  field={field}
                  icon={<MdCalendarMonth />}
                  mask="99"
                  placeholder=""
                />
              )}
            />

            <Label>{t("year")}:</Label>
            <Field
              type="text"
              name="expirationYear"
              render={({ field }: { field: FieldInputProps<any> }) => (
                <MaskedInput
                  field={field}
                  icon={<MdCalendarMonth />}
                  mask="9999"
                  placeholder=""
                />
              )}
            />

            <MainButtonModal type="submit">
              {t("add-a-credit-card")}
            </MainButtonModal>
          </Form>
        </Formik>
      </ModalWrapper>
    </div>
  );
};

export default CreditCardPage;
