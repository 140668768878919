import styled from "styled-components";
import colors from "../../utils/colors";

interface ButtonProps {
  selected: boolean;
}

export const Container = styled.div`
  @media only screen and (max-width: 480px) {
    flex-direction: row;
    display: flex;
    margin-top: -30px;
  }
`;

export const Button = styled.button<ButtonProps>`
  border: 2px solid #48C85D;
  background-color: ${({ selected }) =>
    selected ? `#48C85D` : "transparent"};
  color: ${({ selected }) => (selected ? "#fff" : `#48C85D`)};
  cursor: pointer;
  margin-left: 5vh;
  margin-right: 5vh;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 30px;
  border-radius: 10px;
  height: 40px;
  width: 200px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #48C85D;
    color: #fff;
  }

  font-family: "poppins";
  font-size: 18px;
  text-align: center;

  @media only screen and (max-width: 480px) {
    height: 40px;
    width: 150px;
  }
`;
