import Navbar from "../../../components/navbar/navbar";
import { CiHome } from "react-icons/ci";
import {
  ContainerCard,
  Title,
  ContainerTitle,
  Container,
  CardWrapper,
} from "./style";
import Card from "../../../components/card/card";

import casa from "../../../assets/images/casa.png";
import HomeButtons from "../../../components/button-home/button-home";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import axios from "axios";
import { baseSeller } from "../../../utils/server/api";
import PropertySlider from "../../../components/slider-property/sliderProperty";
import Sliders from "../../../components/slider/slider";
import { GridContainer } from "../home-buy/style";
import MoreCard from "../../../components/more-card/more-card";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import Flys from "../../../components/flys/flys";

const banner = require("../../../assets/images/3.png");

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function HomeSeller() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [property, setProperty] = useState([]);
  const [myProperty, setMyProperty] = useState([]);

  const handleProperty = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
        },
      };

      const response = await axios.get(
        `${baseSeller}/product/products-by-user`,
        config
      );
      setProperty(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  const handlePropertyMy = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
      },
    };
    try {
      const response = await axios.get(
        `${baseSeller}/product/products-by-user`,
        config
      );
      setMyProperty(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleProperty();
    handlePropertyMy();
  }, []);
  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    navigate(`/detailsPropertySeller${queryString}`);
  };

  const limitedItems = property.slice(0, 5);

  return (
    <Container>
      <Navbar />

      <ContainerTitle>
        <CiHome size={25} />
        <Title>{t("Área do vendedor")}</Title>
      </ContainerTitle>

      <Footer />
    </Container>
  );
}
