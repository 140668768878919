import styled, { css } from "styled-components";
import colors from "../../../../../utils/colors";

export const Container = styled.button`
    display: flex;
    margin: 40px;
    height: 100%;
    width: 90%;

    .cardPayment {
        width: 50%;
        display: block;
        align-items: center;
        justify-content: center;
    }
`;

export const ButtonAddCard = styled.button`
    display: flex;
    align-items: center;  
    justify-content: center;  
    border: 1px solid ${colors.black};
    margin-bottom: 40px;
    border-radius: 10px;
    width: auto;
    height: 85px;
    padding: 0 20px;
    color: #0788D1;
`;

export const DataButton = styled.button`
  display: flex;
  font-size: 30px;
  gap: 60px;
  font-family: "poppins";
`;

export const ButtonBuy = styled.button`
    font-size: 18px;
    width: 550px;
    height: 70px;
    background-color: #e1e7d6;
    color: #50700f;
    border-radius: 10px;
    font-family: "poppins";
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 50px;
    float: left;

    &:hover {
        background-color: #d0d8c0;
    }

    &:disabled {
        background-color: #a9a9a9;
        color: #6c6c6c;
        cursor: not-allowed;
    }

    ${(props) =>
        props.disabled &&
        css`
            background-color: #a9a9a9;
            color: #6c6c6c;
            cursor: not-allowed;
        `}
`;

export const CreditCard = styled.div`
  width: 550px;
  height: 400px;
  border-radius: 20px;
  border: 10px solid #50700F;
`;

export const Line = styled.div`
  margin-top: 60px;
  width: 100%;
  background-color: #50700F;
  height: 12px;
`;


export const NumberCard = styled.p`
  text-align: start;
  padding-top: 140px;
  font-size: 25px;
  margin-left: 20px;
  font-family: "poppins";
`;

export const SurnamemeCard = styled.p`
  text-align: start;
  padding-top: 60px;
  font-size: 25px;
  margin-left: 20px;
  font-family: "poppins";
`;

