import { useEffect, useState, useRef } from "react";
import {
    GoogleMap,
    Marker,
    useLoadScript,
    Autocomplete,
} from "@react-google-maps/api";
import configMaps from "./configMaps";
import { InputMap, ButtonMap } from "./MapComponentStyle";

interface MapOptions {
    lat: number;
    lng: number;
}

const MapComponent = ({
    getAddressComponent,
    setAddress,
    setLat,
    setLng,
    view,
}: {
    getAddressComponent: any;
    setAddress: any;
    setLat: any;
    setLng: any;
    view: boolean;
}) => {
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [selectedPlace, setSelectedPlace] = useState<MapOptions>({
        lat: 0,
        lng: 0,
    });
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [getAddress, setGetAddress] = useState("");
    const autocompleteRef = useRef<any>(null);

    useEffect(() => {
        if (setLat && setLng) {
            const lat = parseFloat(setLat);
            const lng = parseFloat(setLng);
            setLatitude(setLat);
            setLongitude(setLng);
            setCenter({ lat, lng });
            setSelectedPlace({ lat, lng });
        }
        if (setAddress) {
            setGetAddress(setAddress);
        }
    }, [setLat, setLng, setAddress]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: configMaps.googleMapsApiKey,
        libraries: ["places", "geocoding"],
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    const onPlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry && place.geometry.location) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            //setCenter({ lat, lng });
            //setSelectedPlace({ lat, lng });
            setLatitude(lat.toString());
            setLongitude(lng.toString());
            //getAddressComponent(place);
        }
    };

    const isDecimal = (coord: any) => {
        return !isNaN(coord) && coord.toString().indexOf("°") === -1;
    };

    const dmsToDecimal = (dms: any) => {
        const sanitizedDms = dms.replace(/\s+/g, "");
        const regex = /(\d+)(°)(-?\d+)(')\s*(-?\d+[,.]?\d*)["]?\s*([NSLOEW])?/;

        const matches = sanitizedDms.match(regex);

        if (!matches) return null;

        let degrees = parseFloat(matches[1]);
        let minutes = parseFloat(matches[3]);
        let seconds = parseFloat(matches[5].replace(",", "."));
        let direction = matches[6];

        let decimal = degrees + minutes / 60 + seconds / 3600;

        if (direction === "S" || direction === "W" || direction === "O") {
            decimal *= -1;
        }

        return decimal;
    };

    const convertToDecimal = (coord: any): any => {
        if (isDecimal(coord)) {
            return parseFloat(coord);
        } else {
            return dmsToDecimal(coord);
        }
    };

    const handleFilter = () => {
        if (latitude && longitude) {
            const lat = convertToDecimal(latitude);
            const lng = convertToDecimal(longitude);
            setCenter({ lat, lng });
            setSelectedPlace({ lat, lng });
        }
    };

    return (
        <>
            <div
                style={{
                    position: "absolute",
                    right: 10,
                    zIndex: 1,
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                    marginTop: "60px",
                }}
            >
                <InputMap
                    type="text"
                    placeholder="Latitude"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                />
                <InputMap
                    type="text"
                    placeholder="Longitude"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                />
                <Autocomplete
                    onLoad={(autocomplete) =>
                        (autocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={onPlaceChanged}
                >
                    <InputMap type="text" placeholder="Digite o endereço" />
                </Autocomplete>
                <ButtonMap onClick={handleFilter}>Localizar</ButtonMap>
            </div>
            {view && (
                <GoogleMap
                    mapContainerStyle={{ height: "100vh", width: "100%" }}
                    center={center}
                    zoom={15}
                    options={{
                        mapTypeId: window.google.maps.MapTypeId.SATELLITE,
                        mapTypeControl: true,
                        mapTypeControlOptions: {
                            style: window.google.maps.MapTypeControlStyle
                                .DROPDOWN_MENU,
                            mapTypeIds: [
                                window.google.maps.MapTypeId.ROADMAP,
                                window.google.maps.MapTypeId.SATELLITE,
                                window.google.maps.MapTypeId.HYBRID,
                                window.google.maps.MapTypeId.TERRAIN,
                            ],
                        },
                        streetViewControl: true,
                    }}
                >
                    {selectedPlace && (
                        <Marker
                            position={{
                                lat: selectedPlace.lat,
                                lng: selectedPlace.lng,
                            }}
                            icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                scaledSize: new window.google.maps.Size(40, 40),
                            }}
                        />
                    )}
                </GoogleMap>
            )}
        </>
    );
};

export default MapComponent;
