import { useState } from "react";
import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Container, Content } from "./style";
import { useLocation } from "react-router-dom";
import MapComponent from "../../components/googleMaps/MapComponent";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Satelite() {
    const query = useQuery();
    const lat = parseFloat(query.get("lat") || "-15.7942");
    const lng = parseFloat(query.get("lng") || "-47.8822");

    const [addressComponent, setAddressComponent] = useState<any>();

    const getAddressComponent = (addressData: any) => {
        setAddressComponent(addressData);
    };

    return (
        <Container>
            <NavBar />
            <Content>
                <MapComponent
                    getAddressComponent={getAddressComponent}
                    setAddress={""}
                    setLat={lat}
                    setLng={lng}
                    view={true}
                />
            </Content>
            <Footer />
        </Container>
    );
}
