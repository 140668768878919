import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomeRouter = () => {
    const navigate = useNavigate();
    const type = localStorage.getItem("userType");
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token && type === "seller") {
            navigate("/homeBuy");
        } else if (token && type === "engineer") {
            navigate("/homeBuy");
        } else if (token && type === "buyer") {
            navigate("/homeBuy");
        } else {
            navigate("/homeBuy");
        }
    });

    return (<></>);
};

export default HomeRouter;