export async function payment(card: any) {

  const token = "SEU_TOKEN_DE_AUTORIZAÇÃO_AQUI"; // Adicione seu token de autenticação

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: {
        number: "4111111111111111",
        verification_value: "999",
        first_name: "Amanda",
        last_name: "Santos",
        month: "03",
        year: "2028",
      },
      test: true,
      account_id: "437E49D2AA0B43829EB3FE90F2F12F91",
      method: "credit_card",
    }),
  };

  try {
    const response = await fetch(
      "https://api.iugu.com/v1/payment_token",
      options
    );
    const data = await response.json();
  } catch (error) {
    console.error(error);
  }
}
