import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MySpaceRouter = () => {
    const navigate = useNavigate();
    const type = localStorage.getItem("userType");
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (token && type === "seller") {
            navigate("/mySpaceSeller");
        }

        else if (token && type === "engineer") {
            navigate("/mySpaceEngineer");
        }

        else if (token && type === "buyer") {
            navigate("/mySpaceBuy");
        }

        else {
            navigate("/choice");
        }
    }, []);

    return <></>;
};

export default MySpaceRouter;
