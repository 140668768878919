import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Content = styled.div`
    flex-direction: row;
    display: flex;
    margin-top: -190px;
    margin-bottom: -80px;
`;

export const Space = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh; /* 100% da altura da tela */
`;

export const Img = styled.img`
  width: 30%;
  margin-left: 5px;

  @media only screen and (min-width: 768px) {
    margin-top: 1rem;
    width: 100%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (min-width: 1024px) {
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1440px) {
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1441px) {
    width: 100%; /* A largura será 100% do contêiner pai */
  }
`;

export const ButtonImg = styled.button`
  font-family: "Josefin Sans";
  color: #fff;
  font-size: 18px;
  margin-top: 15px;
  background-color: #48c85d;
  border-radius: 8px;
  height: 60px;
  left: 0;
  top: 0;
  width: 550px;
  border: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  @media only screen and (max-width: 480px) {
    height: 50px;
    width: 80%;
  }

  @media only screen and (min-width: 768px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    height: 10%;
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1441px) {
    height: 5%;
    width: 90%;
  }
`;

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 1200px;
`;

export const LinhaVertical = styled.div`
  height: 55vh; /* Ajuste a altura da linha conforme necessário */
  width: 0.2px;
  background-color: #45B7FE; /* Cor da linha */
  margin-top: 28vh;
  border-radius: 4px;
`;

export const TitleInput = styled.h1`
  font-family: "poppins";
  color: #000000;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const ForgotPasswordLinkContainer = styled.a`
  margin-left: auto;
  width: 30%;
  font-family: "poppins";
  color: #515151;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const ButtonEnter = styled.button`
  background-color: #45B7FE;
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 350px;
  border: 0;
  margin-bottom: 20px;
`;

export const ButtonRegister = styled.button`
    height: 45px;
    left: 0;
    top: 0;
    width: 350px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e7d6;
    border-radius: 8px;
`;

export const TitleButtonEnter = styled.h1`
  font-family: "poppins";
  color: #fff;
  font-size: 18px;
`;

export const TitleButtonRegister = styled.h1`
  font-family: "poppins";
  color: #50700F;
  font-size: 18px;
`;

export const Title = styled.h1`
  font-family: "poppins";
  color: #000000;
  font-size: 25px;
  margin-top: 150px;
`;

export const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
  font-family: "poppins";
  font-size: 12px;
`;

export const MarginBottom = styled.div`
  margin-bottom: 20px;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
`;
