import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

export const StyledSlider = styled(Slider)`
    width: 100%;

    .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const SlideImage = styled.div<{ src: string }>`
    width: 100%;
    height: 434px;
    background-image: url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
        height: 300px;
    }

    @media (max-width: 480px) {
        height: 200px;
    }
`;

export const Img = styled.img`
    width: 50px;
`;
