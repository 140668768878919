import React, { useState } from "react";
import { FieldInputProps } from "formik";
import { RiLockPasswordLine, RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { InputContainer, InputField, IconContainer } from "./style";

type PasswordInputWithToggleProps = {
    field?: FieldInputProps<any>;
    placeholder: string;
};

const PasswordInputWithToggle: React.FC<PasswordInputWithToggleProps> = ({
    field,
    placeholder,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <InputContainer>
            <InputField
                {...field}
                type={showPassword ? "text" : "password"}
                placeholder={placeholder}
            />
            <IconContainer onClick={togglePasswordVisibility}>
                {showPassword ? <RiEyeOffLine size="23px" /> : <RiEyeLine size="23px" />}
            </IconContainer>
        </InputContainer>
    );
};

export default PasswordInputWithToggle;
