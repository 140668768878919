import React, { useEffect, useState } from 'react';
import {
  Container,
  Description,
  ContainerDescription,
  Title,
  Value,
  Line,
  ContentCard,
  ContainerNoItems
} from "./style";
import Navbar from "../../components/navbar/navbar";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import { useServiceShoppingCart } from "../../hooks/serviceShoppingCart";

import CardProduct from '../../components/card-product/card-product';
import PaymentMethods from "./components/Payment-methods";
import { storageServerPath } from '../../utils/server/api';

export default function Order() {

  type Favorite = {
      id: number;
      product: {
          id: number;
          image: string;
          name: string;
          qtd_carbon: number;
      };
      price: number;
      qty: number;
  };

  const initialFavorites: Favorite[] = [
    {
      id: 0,
      product: {
        id: 0,
        image: "",
        name: "",
        qtd_carbon: 0,
      },
      price: 0,
      qty: 0
    },
  ];

  const [deletecards, setDeleteCards] = useState(initialFavorites);
  const totalValue = deletecards.reduce((total, item) => total + item.price * item.qty, 0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoading = false;
  const { getCart, removeItem, alterQuantity } = useServiceShoppingCart();


  useEffect(() => {
      async function fetchCart() {
          const data = await getCart();

          if (data.status === 'waiting') {
            navigate(
                `/${
                    data.payment_method === "boleto"
                        ? "ticket"
                        : "CreditCardPayment"
                }`,
                {
                    state: { response: data?.tickets[0] },
                }
            );
          }

          if (
              data.status !== "waiting" &&
              data.status !== "opened" &&
              data.status !== "error"
          ) {
              setDeleteCards(initialFavorites);
              return;
          }

          let newData: Favorite[] = data.items.map((item) => ({
                id: item.id,
              product: {
                  id: item.property?.id,
                  image:
                      storageServerPath +
                      item.property?.file_images?.split(";")[0],
                  name: `${item.property?.name}`,
                  qtd_carbon: item.property?.carbon_credits_available,
              },
              price: parseFloat(item.property.cost),
              qty: Number(item.quantity) || 0,
          }));

          setDeleteCards(newData);
      }

      fetchCart();
  }, []);

  const deleteItem = async (id: number) => {
    await removeItem(id).then(() => {
      setDeleteCards((currentDeleteCards) =>
          currentDeleteCards.filter((card) => card.id !== id)
      );
    })
  };

  const updateQuantity = (id: number, newQty: number, propertyId: number) => {
    setDeleteCards(currentCards =>
      currentCards.map(card =>
        card.id === id ? { ...card, qty: newQty } : card
      )
    );

    alterQuantity({
        propertyId: propertyId,
        quantity: newQty,
    }).then(() => {
        console.log('alter quantity is sucessfully.');
    });
  };

  return (
      <>
          <Navbar />
          <Breadcrumb itens={[t("order")]} />
          {deletecards.length > 0 && deletecards[0].id > 0 ? (
              <Container>
                  <Description>
                      <ContainerDescription>
                          <div>
                              <Title>{t("amount")}</Title>
                              <Value>
                                  R${" "}
                                  {totalValue.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                  })}
                              </Value>
                          </div>
                      </ContainerDescription>
                      <Line />
                      {!isLoading && (
                          <>
                              {deletecards.map((item, index) => (
                                  <ContentCard key={index}>
                                      <CardProduct
                                          id={item.product.id}
                                          image={item.product.image}
                                          title={item.product.name}
                                          price={item.price}
                                          qtd_carbon={item.qty}
                                          minQty={1}
                                          maxQty={item.product.qtd_carbon}
                                          deleteItem={() => deleteItem(item.id)}
                                          updateQuantity={(newQty) =>
                                              updateQuantity(
                                                  item.id,
                                                  newQty,
                                                  item.product.id
                                              )
                                          }
                                      />
                                  </ContentCard>
                              ))}
                          </>
                      )}
                  </Description>
                  <PaymentMethods amount={totalValue} />
              </Container>
          ) : (
              <ContainerNoItems>
                  <Title>{t("no-order")}</Title>
                  <p>
                      {t("order-description")}
                      <button
                          onClick={() => {
                              navigate("/news");
                          }}
                          style={{
                              color: "#0788D1",
                              fontFamily: "poppins",
                          }}
                      >
                          {t("Click-here")}
                      </button>
                  </p>
              </ContainerNoItems>
          )}
          <Footer />
      </>
  );
}
