import React, { useState } from 'react';
import {
  ContainerPay,
  ContentPay,
  ButtonDiv,
  ButtonPay,
  ButtonSearch,
  Payment,
} from "../style";

import { BsQrCode } from "react-icons/bs";
import { PiCreditCardLight } from "react-icons/pi";
import { CiFileOn } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useServiceShoppingCart } from "../../../hooks/serviceShoppingCart";

type PaymentType = 'card' | 'pix' | 'boleto' | null;

interface payloadToCreate {
  amount: number;
}

export default function PaymentMethods({amount}: payloadToCreate) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentType>(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handlePaymentSelection = (paymentType: PaymentType): void => {
      setSelectedPaymentMethod(paymentType);
      setButtonDisabled(false);
  };

  const { paymentCreate } = useServiceShoppingCart();

  const createPaymentApi = async () => {

    if (selectedPaymentMethod === 'boleto') {
      const data = {
          amount: amount,
          payment_method: selectedPaymentMethod,
      };

      await paymentCreate(data)
          .then((response) => {
              navigate(
                  `/${selectedPaymentMethod === "boleto" ? "ticket" : ""}`,
                  {
                      state: { response: response.data },
                  }
              );
          })
          .catch((error) => {
              //mexer aqui
          });
    }

    if (selectedPaymentMethod === "card") {
        navigate('/CreditCardPayment', {
            state: { response: {amount: amount} },
        });
    }
  }

  return (
      <>
          <ContainerPay>
              <div
                  style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                  }}
              >
                  <ContentPay>
                      <Payment
                          isSelected={selectedPaymentMethod === "card"}
                          onClick={() => handlePaymentSelection("card")}
                      >
                          <PiCreditCardLight size={35} />
                          <div style={{ marginLeft: "10px" }}>
                              <p>{t("credit-card")}</p>
                          </div>
                      </Payment>

                      {/* <Payment 
                  isSelected={selectedPaymentMethod === 'pix'}
                  onClick={() => handlePaymentSelection('pix')}>
                  <BsQrCode size={35}  />
                  <div>
                    <p>{t("pix-key")}</p>
                  </div>
                </Payment> */}

                      <Payment
                          isSelected={selectedPaymentMethod === "boleto"}
                          onClick={() => handlePaymentSelection("boleto")}
                      >
                          <CiFileOn size={35} />
                          <div style={{ marginLeft: "10px" }}>
                              <p>{t("ticket")}</p>
                          </div>
                      </Payment>
                  </ContentPay>

                  <ButtonDiv>
                      <ButtonPay
                          onClick={() => {
                              createPaymentApi();
                          }}
                          disabled={buttonDisabled}
                      >
                          {t("Payment")}
                      </ButtonPay>
                      <ButtonSearch
                          onClick={() => {
                              navigate("/news");
                          }}
                      >
                          {t("keep-buying")}
                      </ButtonSearch>
                  </ButtonDiv>
              </div>
          </ContainerPay>
      </>
  );
}
