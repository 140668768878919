import React, {
  InputHTMLAttributes,
  ChangeEvent,
  FocusEvent,
  ReactNode,
} from "react";
import { Icon, InputContainer, InputField } from "./style";
import { Input } from "@chakra-ui/react";
import { FieldInputProps } from "formik";
import { IconType } from "react-icons";

interface InputWithIconProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: ReactNode;
  iconSize?: string;
  type?: string;
  field?: FieldInputProps<any> & {
    onBlur?: (
      e: FocusEvent<any, Element> | React.FocusEvent<HTMLInputElement>
    ) => void;
  };
}

const InputWithIcon: React.FC<InputWithIconProps> = ({
  icon,
  iconSize,
  field,
  type,
  ...inputProps
}) => {
  const { name, onBlur, onChange, value, ...rest } = field || {};

  return (
    <InputContainer>
      <InputField
        name={name}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          onBlur?.(e);
        }}
        onChange={onChange}
        value={value}
        {...inputProps}
        {...rest}
        type={type || "text"}
      />
    </InputContainer>
  );
};

export default InputWithIcon;
