import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import {
    CardImage,
    CardTitle,
    Center,
    Container,
    ContainerCard,
    ContentCard,
    GridContainer,
    Title,
} from "./style";
import Navbar from "../../components/navbar/navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useServiceProperty } from "../../hooks/serviceProperty";
import { AppProperty } from "../../models/Property";
import { storageServerPath } from "../../utils/server/api";
import { useAuth } from "../../context/AuthContext";

export default function News() {
    const navigate = useNavigate();
    const typeUser = localStorage.getItem("userType");
    const { t } = useTranslation();
    const { getArea, openGetArea } = useServiceProperty();

    const [properties, setProperties] = useState<AppProperty[]>([]);
    const { login } = useAuth();
    const token = localStorage.getItem("token");
    const lng = localStorage.getItem("language");

    useEffect(() => {
        if (!token) {
            const initialValues = {
                email: "satelite@satelite.com",
                password: "satelite",
                user_type: "satelite",
            };

            login(initialValues);

            navigate("/news");
            return;
        } else {
            if (typeUser === "engineer") {
                getArea().then((data) => {
                    setProperties(data);
                });
            } else {
                openGetArea().then((data) => {
                    setProperties(data);
                });
            }
        }
    }, []);

    if (properties.length === 0) {
        return (
            <Container>
                <Navbar />
                <Center style={{ marginTop: 30, marginBottom: 30 }}>
                    <Title>{t("no-properties-found")}</Title>
                </Center>
                <Footer />
            </Container>
        );
    }

    return (
        <Container>
            <Navbar />

            <TitleComponent icon={CiHome} text={t("news")} />

            <GridContainer>
                {properties.map((p, i) => (
                    <ContainerCard
                        onClick={() =>
                            navigate(`/detailsPropertySeller/${p.id as number}`)
                        }
                        key={i}
                    >
                        <CardImage
                            src={
                                storageServerPath +
                                p?.file_images?.split(";")[0]
                            }
                        />

                        <ContentCard>
                            <CardTitle>
                                {lng === "en" ? p.name_en || "" : p.name}
                            </CardTitle>

                            <p>
                                {lng === "en"
                                    ? (p.description_en || "").slice(0, 50)
                                    : (p.description || "").slice(0, 50)}
                            </p>

                            <p>{p.cost_formatted}</p>
                        </ContentCard>
                    </ContainerCard>
                ))}
            </GridContainer>

            <Footer />
        </Container>
    );
}
