import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useServiceProperty } from "../../../hooks/serviceProperty";
import { AppProperty } from "../../../models/Property";
import {
  Container,
  Center,
  Title,
  Button,
  GridContainer,
  ContainerCard,
  CardImage,
  CardTitle,
  ContentCard,
} from './style';
import { storageServerPath } from "../../../utils/server/api";

export default function MySpaceProperty() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchProperties } = useServiceProperty();

  const [properties, setProperties] = useState<AppProperty[]>([]);

  useEffect(() => {
      fetchProperties().then((data) => {
          setProperties(data);
      });
  }, []);

  if (properties.length === 0) {
    return (
      <Container>
        <Center>
          <Title>{t("empty-properties-warning-1")}</Title>
          <Title style={{marginTop: -10}}>{t("empty-properties-warning-2")}</Title>

          <Button
            onClick={() => navigate("/registerProperty")}
            style={{marginTop: 20}}>{t("register-property")}</Button>
        </Center>
      </Container>
    );
  }

  return (
      <Container>
          <GridContainer>
              {properties.map((p) => (
                  <ContainerCard
                      onClick={() =>
                          navigate(`/detailsPropertySeller/${p.id as number}`)
                      }
                  >
                      <CardImage
                          src={
                              storageServerPath + p?.file_images?.split(";")[0]
                          }
                      />

                      <ContentCard>
                          <CardTitle>{p.name}</CardTitle>

                          <p>{(p.description || "").slice(0, 50)}</p>

                          <p>{p.cost_formatted}</p>

                          <p
                              className={
                                  p.status === "active"
                                      ? "greeText"
                                      : p.status === "inactive"
                                      ? "redText"
                                      : "orangeText"
                              }
                          >
                              {t(p.status)}
                          </p>
                      </ContentCard>
                  </ContainerCard>
              ))}
          </GridContainer>

          <Center>
              <Button
                  onClick={() => navigate("/registerProperty")}
                  style={{ marginTop: 20 }}
              >
                  {t("register-property")}
              </Button>
          </Center>
      </Container>
  );
}
