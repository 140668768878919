import React from 'react';
import './Modal.css';

const ModalDefault = ({
    showModal,
    setShowModal,
    children,
    onClose,
}: {
    showModal: any;
    setShowModal: any;
    children: any;
    onClose: any;
}) => {
    const handleClose = () => {
        setShowModal(false);
        if (onClose) {
            onClose();
        }
    };

    if (!showModal) return null;

    return (
        <div className="modal-overlay" onClick={handleClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {children(handleClose)}
            </div>
        </div>
    );
};

export default ModalDefault;

