import { useQuery } from "@tanstack/react-query";
import { apiBase } from "../../src/utils/server/api";
import axios from "axios";

const fetchMe = async () => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(`${apiBase}/user`, config);
    return { ...response.data };
  } catch (error) {
    console.error(error);
  }
};

export function useMeData() {
  const query = useQuery({
    queryFn: fetchMe,
    queryKey: ["me"],
  });

  return query;
}
