import React, { useState } from "react";
import { Button, Container } from "./style";
import { useTranslation } from "react-i18next";

interface ButtonSelectProps {
  onSelectChange: (selectedOption: number) => void;
  options: string;
}

const ButtonSelect: React.FC<ButtonSelectProps> = ({ onSelectChange, options }) => {
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const { t } = useTranslation();
  const handleButtonClick = (option: number) => {
    setSelectedOption(option);
    onSelectChange(option);
  };

  return (
      <Container>
          <Button
              selected={selectedOption === 1}
              onClick={() => handleButtonClick(1)}
              hidden={options !== "all"}
          >
              {t("physical-person")}
          </Button>
          <Button
              selected={selectedOption === 2}
              onClick={() => handleButtonClick(2)}
              hidden={options !== "all"}
          >
              {t("legal-person")}
          </Button>
      </Container>
  );
};

export default ButtonSelect;
