import styled from "styled-components";
import colors from "../../utils/colors";

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  align-items: start;
`;

export const Logo = styled.img`
    width: 180px !important;
    min-width: 180px !important;
`;

export const ButtonLogin = styled.button`
  background-color: ${colors.primary};
  border-radius: 4px;
  width: 100px;
  height: 30px;
`;

export const TextLogin = styled.p`
  font-family: "poppins";
  font-size: 16px;
  color: #fff;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Efeito de elevação (box shadow) */
  background-color: ${colors.primary};
`;

export const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  width: 210px;
  height: 30px;
`;

export const OptionText = styled.span`
  font-family: "poppins";
  font-size: 16px;
  margin-left: 5px;
  padding-top: 5px;
`;

export const TitleInput = styled.h1`
  font-family: "poppins";
  color: #515151;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px;
`;

export const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-right: 8px;
  font-size: 14px;
  width: 90%;

  ::placeholder {
    font-family: "poppins";
    color: "#dfcfcf";
  }
`;

export const SearchButton = styled.button`
  background-color: #6bca6e; /* Cor verde */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 10%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Placeholder = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-family: "poppins";
  color: "#515151";
  pointer-events: none;
`;

export const Title = styled.h1`
  font-family: "poppins";
  color: #000;
  font-size: 22px;
`;

export const TextInformation = styled.h1`
  font-family: "poppins";
  color: #000;
  font-size: 15px;
`;

export const MarginRight = styled.div`
  margin-right: 20px;
`;

export const Button = styled.button`
  background-color: ${colors.primary};
  border-radius: 8px;
  height: 35px;
  width: 80%;
  border-right-color: "#000";

  font-family: "poppins";
  color: #fff;
`;

export const LanguageText = styled.p`
  font-family: "poppins";
  color: #000;
`;

export const Language = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`;


export const Inputsearch = styled.input`
  width: 350px;
  height: 36px;
  border-Bottom: 2px solid #95D2F1;
  background: none;
  outline: none;
  background-Position: 10px center;
  background-Size: 20px 20px;
  background-Repeat: no-repeat;
  padding: 0px 0px 0px 35px;
`
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  margin-left: 2rem;
  
  @media screen and (min-width: 1024px) {
    margin-left: 1rem;
    gap: 1rem;
  }
`;
export const NavbarText = styled.a`
  cursor: pointer;
    color: #50700F;
    font-family: "poppins";
    font-size: 16px;
    &:hover {
    border-bottom: #96D2F4 solid 1px; 

  }
`;

export const NavbarTextLogin = styled.a`
  cursor: pointer;
    color: #50700F;
    font-family: "poppins";
    font-size: 16px;

    &:hover {
    border-bottom: #96D2F4 solid 1px; 

  }
`;

export const SearchBar = styled.div`
  display: flex;
  color: #50700F;
`;

export const Welcome = styled.div`
display: flex;
  margin-left: 70px;
  font-family: "poppins";
  font-size: 15px;
  align-items: center;
`;

export const Pointer = styled.span`
  cursor: pointer;
`;

export const Container = styled.div`
  align-items: center;
  position: relative;
  top: 0;
  z-index: 99;
  padding: 10px 20px;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #000000;

`;

export const MagnifierSearch = styled.img`
width: 25px;
margin: 10px 0px 0px 0px;
position: absolute;
cursor: pointer;
`
export const Image = styled.img`
width: 10px;
`

export const DropdownLanguage = styled.select`
display: flex;
  width: 72px;
  color: #50700F;
  font-family: "poppins";
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: white solid 1px;
  background-color: transparent;
`

export const IconOrder = styled.div`
  border: 3px solid #50700F;
  border-radius: 50%;
  padding: 5px;

`

export const ButtonNewProperty = styled.button`
  border: 3px solid #50700F;
  border-radius: 40px;
  font-family: "poppins";
  font-size: 13px;
  width: 100px;
  height: 45px;
  color: #50700F;
  cursor: pointer;
`