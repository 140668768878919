import Navbar from "../../components/navbar/navbar";
import TitleComponent from "../../components/title/title";
import {
  GridContainer,
  Container,
  ContainerCard,
  SearchIncorrect,
  SubTitle,
  Title,
  ButtonNovidades
} from "./style";
import { FaSearch } from "react-icons/fa";
import Card from "../../components/card/card";
import { useEffect, useState } from "react";
import { config } from "../../utils/server/header";
import axios from "axios";
import { baseBuyer } from "../../utils/server/api";
import { Route, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import { useTranslation } from "react-i18next";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function Search() {
  const location = useLocation();
  const { t } = useTranslation();
  const [product, setProduct] = useState<any[]>([
    // Dados fictícios para substituição
    {
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF9rshvzVQvFZAKrQBzKIiTPbPKmtcJ798IFTcNwInPQ&s",
      name: "Casa Verde",
      carbon_amount: "80",
      price: "3.540",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/foto-aerea-de-um-rio-cercado-por-ilhas-cobertas-de-vegetacao-sob-a-luz-solar_181624-6504.jpg?w=1060&t=st=1715281009~exp=1715281609~hmac=b56d122e27ef96236776a7a0fbda3c96b9e5a99dc9eb528b5115cc4615c13892",
      name: "Fazenda Sol Nascente",
      carbon_amount: "60",
      price: "150.000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/bela-vista-aerea-com-paisagem-verde-floresta-no-crepusculo_74190-7496.jpg?w=1060&t=st=1715281107~exp=1715281707~hmac=d3c9f4de998f5e298ffc967539158f96058fcb738f88fa1a17a3272c39791824",
      name: "Apartamento Urbano",
      carbon_amount: "1200",
      price: "20.000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/foto-aerea-de-um-rio-cercado-por-ilhas-cobertas-de-vegetacao-sob-a-luz-solar_181624-6504.jpg?w=1060&t=st=1715281009~exp=1715281609~hmac=b56d122e27ef96236776a7a0fbda3c96b9e5a99dc9eb528b5115cc4615c13892",
      name: "Mansão de Luxo",
      carbon_amount: "2356",
      price: "50.0000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/linda-e-ameacada-onca-americana-no-habitat-natural-panthera-onca-selvagem-brasil-vida-selvagem-brasileira-pantanal-selva-verde-gatos-grandes_475641-2191.jpg?w=1380&t=st=1715281053~exp=1715281653~hmac=230e831f21b845730b1da03bdd5c0f1d0189ae08f3f6a32e019f48f4f62bedc7",
      name: "Cabana na Montanha",
      carbon_amount: "300",
      price: "3.000",
    },
    {
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF9rshvzVQvFZAKrQBzKIiTPbPKmtcJ798IFTcNwInPQ&s",
      name: "Casa Verde",
      carbon_amount: "400",
      price: "2.000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/foto-aerea-de-um-rio-cercado-por-ilhas-cobertas-de-vegetacao-sob-a-luz-solar_181624-6504.jpg?w=1060&t=st=1715281009~exp=1715281609~hmac=b56d122e27ef96236776a7a0fbda3c96b9e5a99dc9eb528b5115cc4615c13892",
      name: "Fazenda Sol Nascente",
      carbon_amount: "ncríeis",
      price: "1.5000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/bela-vista-aerea-com-paisagem-verde-floresta-no-crepusculo_74190-7496.jpg?w=1060&t=st=1715281107~exp=1715281707~hmac=d3c9f4de998f5e298ffc967539158f96058fcb738f88fa1a17a3272c39791824",
      name: "Apartamento Urbano",
      carbon_amount: " 4000",
      price: "2.000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/foto-aerea-de-um-rio-cercado-por-ilhas-cobertas-de-vegetacao-sob-a-luz-solar_181624-6504.jpg?w=1060&t=st=1715281009~exp=1715281609~hmac=b56d122e27ef96236776a7a0fbda3c96b9e5a99dc9eb528b5115cc4615c13892",
      name: "Mansão de Luxo",
      carbon_amount: "1378",
      price: "5.000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/linda-e-ameacada-onca-americana-no-habitat-natural-panthera-onca-selvagem-brasil-vida-selvagem-brasileira-pantanal-selva-verde-gatos-grandes_475641-2191.jpg?w=1380&t=st=1715281053~exp=1715281653~hmac=230e831f21b845730b1da03bdd5c0f1d0189ae08f3f6a32e019f48f4f62bedc7",
      name: "Cabana na Montanha",
      carbon_amount: "5732",
      price: "3.000",
    },
    {
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF9rshvzVQvFZAKrQBzKIiTPbPKmtcJ798IFTcNwInPQ&s",
      name: "Casa Verde",
      carbon_amount: "5342",
      price: "1.230",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/foto-aerea-de-um-rio-cercado-por-ilhas-cobertas-de-vegetacao-sob-a-luz-solar_181624-6504.jpg?w=1060&t=st=1715281009~exp=1715281609~hmac=b56d122e27ef96236776a7a0fbda3c96b9e5a99dc9eb528b5115cc4615c13892",
      name: "Fazenda Sol Nascente",
      carbon_amount: "46546",
      price: "1.500",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/bela-vista-aerea-com-paisagem-verde-floresta-no-crepusculo_74190-7496.jpg?w=1060&t=st=1715281107~exp=1715281707~hmac=d3c9f4de998f5e298ffc967539158f96058fcb738f88fa1a17a3272c39791824",
      name: "Apartamento Urbano",
      carbon_amount: " 2342",
      price: "2.450",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/foto-aerea-de-um-rio-cercado-por-ilhas-cobertas-de-vegetacao-sob-a-luz-solar_181624-6504.jpg?w=1060&t=st=1715281009~exp=1715281609~hmac=b56d122e27ef96236776a7a0fbda3c96b9e5a99dc9eb528b5115cc4615c13892",
      name: "Mansão de Luxo",
      carbon_amount: "5646",
      price: "5.000",
    },
    {
      image: "https://img.freepik.com/fotos-gratis/linda-e-ameacada-onca-americana-no-habitat-natural-panthera-onca-selvagem-brasil-vida-selvagem-brasileira-pantanal-selva-verde-gatos-grandes_475641-2191.jpg?w=1380&t=st=1715281053~exp=1715281653~hmac=230e831f21b845730b1da03bdd5c0f1d0189ae08f3f6a32e019f48f4f62bedc7",
      name: "Cabana na Montanha",
      carbon_amount: "1234",
      price: "3.000",
    }
  ]);
  
  const [originalProduct, setOriginalProduct] = useState<any[]>([]);
  const navigate = useNavigate();
  const typeUser = localStorage.getItem("userType");
  const token = localStorage.getItem("token");
  const searchParams = new URLSearchParams(location.search);
  const item = JSON.parse(decodeURIComponent(searchParams.get("item") || "{}"));
  const [searchTerm, setSearchTerm] = useState(item);
  const [hasNoResults, setHasNoResults] = useState(false);

  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    if (typeUser === "vendedor" && token) {
      navigate(`/detailsPropertySeller${queryString}`);
    } else {
      navigate(`/detailsPropertyBuy${queryString}`);
    }
  };

  useEffect(() => {

    handleProperty();
    handleSearch();
  }, [searchTerm, item]);

  const handleProperty = async () => {
    try {
      const response = await axios.get(
        `${baseBuyer}/product?name=${searchTerm}`,
        config()
      );
      setProduct(response.data);
      setOriginalProduct(response.data);
    } catch (error) {
      console.error(error);
    }
  };

const handleSearch = () => {
    // Implementação do filtro ou chamada à API.
    const filteredProducts = product.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setProduct(filteredProducts);

    // Verifica se há resultados após a filtragem.
    setHasNoResults(filteredProducts.length === 0);
};

return (
    <Container>
      <Navbar />
      <Breadcrumb itens={["home", t("search")]}/>
      <TitleComponent icon={FaSearch} text={t("Search results")} />

      <GridContainer>
        {hasNoResults ? (
          <SearchIncorrect>
            <Title>Não há anúncios que correspondem à sua busca.</Title>
            <SubTitle>Revise a ortografia da palavra. Caso necessário, para maior assertividade na pesquisa.</SubTitle>
            <SubTitle>Olhe o nosso catálogo, para melhor filtragem. <ButtonNovidades onClick={() => {navigate("/news");}}>Clicando Aqui</ButtonNovidades></SubTitle>
          </SearchIncorrect>
        ) : (
          product.map((item, index) => (
            <ContainerCard key={index}>
              <Card
                image={item?.image}
                title={item?.name}
                description={item?.carbon_amount}
                hectares={item?.hectares_amount}
                price={item?.price}
                onClick={() => handleDetails(item)}
              />
            </ContainerCard>
          ))
        )}
      </GridContainer>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
);
}