// AuthContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import axios from "axios";
import { baseBuyer } from "../utils/server/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface CartContextProps {
  children: ReactNode;
}

interface CartContextType {
  addToCart: (data: any, company_id: number) => void;
  getCart: () => void;
  cart: any;
}

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
  },
};

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<CartContextProps> = ({ children }) => {
  const navigate = useNavigate();
  const [cart, setCart] = useState();
  const addToCart = async (data: any, company_id: number) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
      },
    };
    try {
      const response = await axios.post(
        `${baseBuyer}/order/add-product`,
        data,
        config
      );

      const queryString = `?company_id=${encodeURIComponent(
        JSON.stringify(company_id)
      )}`;

      if (response.data.result === false) {
        const primeiroErro = response.data.errors[0];
        if (primeiroErro.msgs && primeiroErro.msgs.length > 0) {
          const mensagemDeErro = primeiroErro.msgs[0]; // Obtém a primeira mensagem de erro

          // Exibe a mensagem de erro no toast
          toast.error(mensagemDeErro, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error("Erro ao adicionar item ao carrinho", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        toast.success("Item adicionado ao carrinho", {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate(`/cart${queryString}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao adicionar item ao carrinho", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getCart = async () => {
    try {
      const response = await axios.get(
        `${baseBuyer}/order/cart?expand=client`,
        config
      );

      setCart(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        addToCart,
        getCart,
        cart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
