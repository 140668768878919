import NavBar from "../../../components/navbar/navbar";
import { Container, Name, OrderButtons, ProfilePic, Surname } from "./style";
import profile from "../../../assets/images/new.png";
import { ButtonOutline } from "../../../components/button/button";
import { MdOutlineSettings } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";

export default function Profile() {
  return (
    <div>
      <NavBar />
      <Container>
        <ProfilePic src={profile} alt="Foto de Perfil" />
        <Name>José Nascimento</Name>
        <Surname>jose@gmail.com</Surname>
        <OrderButtons>
          <ButtonOutline
            onClick={() => (window.location.href = "/settings")}
            icon={MdOutlineSettings}
            title="Configurações"
          />

          <ButtonOutline
            icon={IoLogOutOutline}
            title="Sair"
            onClick={() => (window.location.href = "/")}
          />
        </OrderButtons>
      </Container>
    </div>
  );
}
