import { useState } from "react";
import {
    ButtonEnter,
    ButtonRegister,
    Container,
    Content,
    ForgotPasswordLinkContainer,
    LinhaVertical,
    Space,
    TitleButtonEnter,
    TitleButtonRegister,
    TitleInput,
    Title,
    ErrorText,
    MarginBottom,
    Column,
    Img,
    DivImg,
} from "./style";
import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/footer";
import { ContainerMargin } from "../../cart/style";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import InputWithIcon from "../../../components/input/input";
import logo from "../../../assets/images/new-logo.png";
import {
    Formik,
    Field,
    Form,
    FormikHelpers,
    FieldInputProps,
    ErrorMessage,
} from "formik";
import { Center } from "@chakra-ui/react";

import authValidation from "../../../utils/validation/authValidation";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../../../context/AuthContext";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function LoginEnginner() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { login } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const initialValues = {
        email: "",
        password: "",
        user_type: "engineer",
    };
    const handleRegister = () => {
        navigate("/registerEngineer");
    };

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        try {
            setIsLoading(true);
            login(values);
            setIsLoading(false);
        } catch (error) {
            console.error("Login error:", (error as Error).message);
        }
    };
    return (
        <Container>
            <Navbar />
            <Container>
                <Content>
                    <Space>
                        <DivImg>
                            <Img src={logo} alt="Ecolife" />
                        </DivImg>
                    </Space>
                    <LinhaVertical />
                    <Space>
                        <Title>{t("engineer-title")}</Title>
                        <ContainerMargin>
                            <Formik
                                validationSchema={authValidation}
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                            >
                                <Form>
                                    <TitleInput>{t("email")}</TitleInput>
                                    <Field
                                        type="text"
                                        name="email"
                                        render={({
                                            field,
                                        }: {
                                            field?: FieldInputProps<any>;
                                        }) => (
                                            <InputWithIcon
                                                icon={<MdOutlineEmail />}
                                                iconSize="23px"
                                                field={field}
                                                placeholder={t("type-email")}
                                            />
                                        )}
                                    />
                                    <ErrorText>
                                        <ErrorMessage name="email" />
                                    </ErrorText>
                                    <MarginBottom />
                                    <TitleInput>{t("password")}</TitleInput>
                                    <Field
                                        type="password"
                                        name="password"
                                        render={({
                                            field,
                                        }: {
                                            field: FieldInputProps<any>;
                                        }) => (
                                            <InputWithIcon
                                                icon={<RiLockPasswordLine />}
                                                iconSize="23px"
                                                field={field}
                                                type="password"
                                                placeholder={t("type-password")}
                                            />
                                        )}
                                    />
                                    <ErrorText>
                                        <ErrorMessage name="password" />
                                    </ErrorText>

                                    <MarginBottom />

                                    <Column>
                                        <ForgotPasswordLinkContainer
                                            onClick={() =>
                                                navigate("/forgetPassword")
                                            }
                                        >
                                            {t("remember")}
                                        </ForgotPasswordLinkContainer>
                                    </Column>
                                    <Center>
                                        <ButtonEnter type="submit">
                                            {isLoading ? (
                                                <BeatLoader
                                                    className="spinner"
                                                    size={8}
                                                    color={"#fff"}
                                                />
                                            ) : (
                                                <TitleButtonEnter>
                                                    {t("login")}
                                                </TitleButtonEnter>
                                            )}
                                        </ButtonEnter>
                                    </Center>
                                </Form>
                            </Formik>
                            <Center>
                                <ButtonRegister onClick={handleRegister}>
                                    <TitleButtonRegister>
                                        {t("new-account")}
                                    </TitleButtonRegister>
                                </ButtonRegister>
                            </Center>
                        </ContainerMargin>
                    </Space>
                </Content>
                <ToastContainer />
            </Container>
            <Footer />
        </Container>
    );
}
