import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Content = styled.div`
  flex-direction: row;
  display: flex;

  @media only screen and (max-width: 768px) {
    /* Estilos específicos para tablets */
    /* Por exemplo, reduzir a largura do Container */
    width: 80%;
    flex-direction: column;
  }

  @media only screen and (max-width: 914px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 480px) {
    /* Estilos específicos para celulares */
    /* Por exemplo, reduzir ainda mais a largura do Container */
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Space = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh; /* 100% da altura da tela */
  

  @media only screen and (max-width: 480px) {
    height: 40vh; /* Altura desejada da linha */
    width: 70%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (max-width: 914px) {
    height: 40vh; /* Altura desejada da linha */
    width: 70%; /* A largura será 100% do contêiner pai */
  }
`;

export const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
  font-family: "poppins";
  font-size: 12px;
`;

export const LinhaVertical = styled.div`
  height: 90vh;
  width: 0.2px;
  background-color: #45B7FE;
  margin-top: 5vh;
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    width: 80%;
    flex-direction: column;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 914px) {
    width: 80%;
    height: 0.5px;
    flex-direction: column;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 480px) {
    width: 80%;
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

export const TitleInput = styled.h1`
  font-family: "poppins";
  color: #000000;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ForgotPasswordLinkContainer = styled.a`
  margin-left: auto;
  width: 30%;
  font-family: "poppins";
  color: #515151;
  margin-top: 10px;
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) and (max-height: 1366px) {
    width: 100%;
  }
`;

export const ButtonEnter = styled.button`
  background-color: #45B7FE;
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 350px;
  border: 0;
  margin-bottom: 20px;
`;

export const ButtonRegister = styled.button`
  background-color: #f5f5f5;
  border: 1px solid;
  border-color: #48c85d;
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 350px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
`;

export const TitleButtonEnter = styled.h1`
  font-family: "poppins";
  color: #fff;
  font-size: 18px;
`;

export const TitleButtonRegister = styled.h1`
  font-family: "poppins";
  color: #48c85d;
  font-size: 18px;
`;

export const Title = styled.h1`
  font-family: "poppins";
  color: #515151;
  font-size: 25px;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) and (max-height: 1024px) {
    margin-bottom: 50px;
    font-size: 1px;
  }
`;

export const MarginBottom = styled.div`
  margin-bottom: 20px;
`;

export const Column = styled.div`
  flex-direction: column;
  display: flex;
`;

export const Center = styled.div`
  justify-content: center;
  align-items: center;
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 1000px; 
`;

export const Img = styled.img`
  width: 70%;
  margin-left: 5px;

  @media only screen and (min-width: 768px) {
    margin-top: 1rem;
    width: 70%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (min-width: 1024px) {
    width: 70%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1440px) {
    width: 70%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1441px) {
    width: 70%; /* A largura será 100% do contêiner pai */
  }
`;

export const ContainerMargin = styled.div``;