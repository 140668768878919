import { Property } from "./Property";

type Cart = {
    id: number;
    user_id: number;
    payment_method: string;
    status: string;
    items: CartItems[];
    tickets: Tickets[];
};

type CartItems = {
    id: number;
    cart_id: number;
    property_id: number;
    quantity: number;
    status: string;
    property: Property;
}

type Tickets = {
    id: number;
    boleto_number: number;
    boleto_pdf: string;
    boleto_url: string;
    status: string;
};

type AppCart = Cart & {};

export function parseCart(cart: Cart): AppCart {
    return {
        id: cart.id,
        user_id: cart.user_id,
        payment_method: cart.payment_method,
        status: cart.status,
        items: cart.items,
        tickets: cart.tickets,
    };
}

export function parseCartList(carts: Cart[]): AppCart[] {
    return carts.map((property) => parseCart(property));
}

export { Cart, AppCart, CartItems, Tickets };
