import React, { useRef } from 'react';
import {
  Container,
  ContentQrcode,
  ButtonBack,
  InputContainer,
  InputField,
  CopyButton,
  ButtonBankSlip,
} from "./style-ticket";
import { IoMdArrowRoundForward } from "react-icons/io";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../../../../components/navbar/navbar';
import Breadcrumb from '../../../../../components/breadcrumb/breadcrumb';
import Footer from '../../../../../components/footer/footer';
import { FaRegFileLines } from "react-icons/fa6";

export default function Pix() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const responseData = location.state?.response;

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      alert('Texto copiado!');
    }
  };

  return (
      <>
          <Navbar />
          <Breadcrumb itens={[t("order"), t("payments"), t("ticket")]} />
          <Container>
              <ContentQrcode>
                  <FaRegFileLines size={250} />
                  <div
                      style={{
                          width: "681px",
                          height: "180px",
                          marginTop: "15px",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "70px",
                      }}
                  >
                      <p>
                          Para pagar pelo aplicativo do seu banco, siga o passo
                          a passo:
                          <br />
                          1.Baixar o App do seu banco pela loja compatível com
                          sua plataforma;
                          <br />
                          2.Abrir e fazer o login;
                          <br />
                          3.Buscar pela opção de pagamentos;
                          <br />
                          4.Ler o código de barras ou digitar o número;
                          <br />
                          5.Conferir se as informações de pagamento estão
                          corretas;
                          <br />
                          6.Concluir a operação e salvar o comprovante no seu
                          smartphone.
                      </p>
                      <ButtonBankSlip onClick={() => {
                        window.open(responseData?.boleto_pdf );
                      }}>{t("download-boleto")}</ButtonBankSlip>
                      <InputContainer>
                          <CopyButton onClick={handleCopy}>
                              {t("code")}:
                          </CopyButton>
                          <InputField
                              type="text"
                              ref={inputRef}
                              value={responseData?.boleto_number}
                              readOnly
                          />
                      </InputContainer>
                  </div>
              </ContentQrcode>
              {/* <div>
                  <ButtonBack
                      onClick={() => {
                          navigate("/order");
                      }}
                  >
                      {t("my-orders")} <IoMdArrowRoundForward size={20} />
                  </ButtonBack>
              </div> */}
          </Container>
          <Footer />
      </>
  );
}
