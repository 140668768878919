import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, StyledSlider } from "./style";
import Content from "./components/content";

const Pronouncement = ({ data }: { data: any }) => {
    const sliderRef = useRef<Slider | null>(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };

    return (
        <Container>
            <StyledSlider ref={sliderRef} {...settings}>
                {data.map((post: any) => (
                    <div>
                        <Content data={post} />
                    </div>
                ))}
            </StyledSlider>
        </Container>
    );
};

export default Pronouncement;
