import styled from "styled-components";

export const FooterContainer = styled.footer`
    background-color: #e1e7d6;
    color: #50700f;
    padding: 10px;
    padding-right: 100px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    display: block;
    bottom: 0;
    width: 100%;
    margin-top: 30px;
    
`;

export const FooterContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
`;

export const CompanyInfo = styled.div`
    max-width: 450px;
    line-height: 30px;

`;

export const SocialMedia = styled.div`
    display: flex;
    margin-left: 19.5%;
    gap: 15px;
    a {
        color: #50700f;
        margin-right: 10px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const Copyright = styled.div`
    display: flex;
    align-items: center;
    background: #fff;
    height: 20px;
    margin-top: 30px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    padding-bottom: 10px;

    p {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        margin-left: -300px;
    }
`;

export const MenuFooter = styled.div`
    ul {
        list-style-type: none;
        display: block;
        float: left;
        margin-right: 50px;
        line-height: 35px;
        ;
    }

    span {
        cursor: pointer;
    }
`;

export const Title = styled.h1`
    font-family: "poppins";
    font-size: 18px;
    color: #50700f;
    margin-bottom: 10px;
    font-weight: bold;
`;

export const SubTitle = styled.h2`
    font-family: "poppins";
    font-size: 14px;
    color: #50700f;
    line-height: 35px;
`;

export const FooterImage = styled.img`
width: 30px;
float: left;
`
export const Line = styled.div`
height: 300px;
width: 2px;
background-color: #95D2F1;
`

export const SocialNetwork = styled.p`
margin-top: 15px;
`