import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ProfilePic = styled.img`
  width: 100px; /* Ajuste conforme necessário */
  height: 100px; /* Ajuste conforme necessário */
  border-radius: 50%;
  object-fit: cover;
`;

export const Name = styled.div`
  font-size: 20px; /* Ajuste conforme necessário */
  margin-top: 10px;

  font-family: "Josefin Bold";
`;

export const Surname = styled.p`
  font-size: 14px;
  font-family: "Josefin Sans";
`;

export const OrderButtons = styled.div`
  margin-top: 20px;
  flex-direction: column;
  display: grid;
`;
