import styled from "styled-components";
import colors from "../../../utils/colors";

export const Container = styled.div`
    width: 100%;
    padding: 0;
    box-sizing: border-box;
`;

export const ContainerCard = styled.div`
    float: left;
    border: 1px solid #ede2e2;
    border-radius: 10px;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    cursor: pointer;

    @media (max-width: 768px) {
        max-width: 100%;
        height: auto;
    }
`;

export const ContainerTitle = styled.div`
    display: block;
    margin: 30px;
`;

export const Title = styled.h1`
    font-family: "Poppins", sans-serif;
    color: #000;
    font-size: 22px;
    text-align: center;
`;

export const CardWrapper = styled.div`
    display: inline-block;
    margin-right: 16px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;
`;

export const CardImage = styled.div<{
    src: string;
}>`
    width: 100%;
    height: 230px;
    border-radius: 10px;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
`;

export const Datecard = styled.div`
    margin: 20px;
`;

export const ContentCard = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    p {
        text-overflow: ellipsis;
    }
`;

export const CardTitle = styled.h3`
    font-size: 22px;
    width: 100%;
    color: ${colors.text};
    white-space: normal;
`;

export const Description = styled.p`
    font-size: 18px;
    width: 100%;
    white-space: normal;
    text-overflow: ellipsis;
    margin-bottom: 10px;
`;
