import {
    Container,
    ContainerText,
    ContainerImg,
    Img,
    Title,
    Contentt,
    Body,
    Data,
} from "./styleContent";
import { SlCalender } from "react-icons/sl";
import { storageServerPath } from "../../../utils/server/api";

export interface PostData {
    id: number;
    title: string;
    created_at: string;
    image: string;
    link: string;
    title_en: string;
}

export default function Content({ data }: { data: PostData }) {
    const lng = localStorage.getItem("language");

    function formatDate(dateString: string) {
        const months = [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
        ];

        const months_en = [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december",
        ];

        const date = new Date(dateString);

        const day = date.getDate();
        const month = months[date.getMonth()];
        const month_en = months_en[date.getMonth()];
        const year = date.getFullYear();

        if (lng === "en") {
            return `${month_en} ${day}, ${year}`;
        }

        return `${day} de ${month} de ${year}`;
    }

    return (
        <Body>
            <Container>
                <Contentt>
                    <ContainerText>
                        <Title>
                            <a
                                href={data.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {lng === "en" ? data.title_en : data.title}
                            </a>
                        </Title>
                        <Data>
                            <SlCalender />
                            <p>{formatDate(data.created_at)}</p>
                        </Data>
                    </ContainerText>
                    <ContainerImg>
                        <a href={data.link} target="_blank" rel="noreferrer">
                            <Img
                                src={`${storageServerPath}base64-files/pronouncements/${data.image}`}
                                alt={`${
                                    lng === "en" ? data.title_en : data.title
                                }`}
                            />
                        </a>
                    </ContainerImg>
                </Contentt>
            </Container>
        </Body>
    );
}
