import React from 'react';
import {
  Container,
  List,
  ListItem,
  Icon,
} from './style';

const homeIcon = require('../../assets/images/home-icon.png');
const caretIcon = require('../../assets/images/caret-icon.png');

export type BreadcrumbProps = {
  noHomeIcon?: boolean;
  itens: string[];
}

export default function Breadcrumb(props: BreadcrumbProps) {
  return (
    <Container>
      <List>
        {!props.noHomeIcon && (
          <ListItem style={{marginRight: 25}}>
            <Icon src={homeIcon} alt="Home" />
          </ListItem>
        )}
        {props.itens.map((item, index) => (
          <React.Fragment key={item}>
            <ListItem >
              {item}
            </ListItem>

            {props.itens.length == index + 1 ? null : (
              <ListItem>
                <Icon src={caretIcon} alt="Caret" tiny />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
}
