import styled, {css} from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 50px;
  gap: 40px;
`;

export const ContainerNoItems = styled.div`
  display: flex;
  width: 90%;
  height: 250px;
  align-items: center;
  justify-content: center;
  border: solid 1px #EDE2E2;
  margin:60px auto;
  flex-direction: column;
  text-align: center;
`;
export const Description = styled.div`
  width: 70%;
  border: 1px solid ;
  border-radius: 10px;
  padding: 20px 0;
`;

export const ContainerDescription = styled.div`
  display: block;
  flex-direction: row;
  gap: 72%;
`;
export const Line = styled.div`
  background-color: #EDE2E2;
  height: 1px;
  width: 96%;
  margin-left: 25px;
  margin-top: 30px;
`;

export const Title = styled.h1`
    font-size: 30px;
    color: #50700f;
    width: 95%;
`;

export const Value = styled.p`
  font-size: 20px;
  color:#50700F;
  text-align: right;
  width: 95%;
`;

//card
export const ContentCard = styled.div`
  margin-right: 10px;
  margin-top: 30px;
`;


//Payment-methods
interface PaymentProps {
  isSelected: boolean;
}
export const ContainerPay = styled.div`
width: 30%;
border: 1px solid ;
border-radius: 10px;
height: 500px;
padding: 20px 0;
`;

export const ContentPay = styled.div`
display: flex;
flex-direction: row;
gap: 25px;
margin: 30px;
margin-bottom: 80px;
`;

export const ButtonDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
`;

export const ButtonPay = styled.button`
    height: 80px;
    width: 100%;
    background-color: #e1e7d6;
    color: #50700f;
    border-radius: 10px;
    font-size: 22px;
    padding: 0 20px;

    &:hover {
        background-color: #d0d8c0;
    }

    &:disabled {
        background-color: #a9a9a9;
        color: #6c6c6c;
        cursor: not-allowed;
    }

    ${(props) =>
        props.disabled &&
        css`
            background-color: #a9a9a9;
            color: #6c6c6c;
            cursor: not-allowed;
        `}
`;

export const ButtonSearch = styled.button`
  width: 100%;
  height: 80px;
  background-color: #95D2F1;
  color: white;
  border-radius: 10px;
  font-size: 22px;
  padding: 0 20px;
`;

export const Payment = styled.button<PaymentProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 128px;
  height: 138px;
  background-color: ${props => props.isSelected ? '#E1E7D6' : 'white'};
  color: #50700F;
  border: 1px solid;
  border-radius: 10px;
  font-size: 16px;
  padding-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
`;

