import React, { useEffect, useState } from "react";
import {
    Container,
    ButtonAddCard,
    DataButton
} from "./style";

import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import Card from "../../../components/credit-card/card";
import ModalDefault from "../../../components/modalDefault/ModalDefault";
import MySpaceBankRegister from "./my-space-bank-card";
import { useServiceShoppingCart } from "../../../hooks/serviceShoppingCart";

export default function MySpaceBank() {
    const { t } = useTranslation();
    const { getSavedCards, removeCard } = useServiceShoppingCart();
    const [cards, setCards] = useState<Card[]>([]);

    async function fetchSavedCards() {
        try {
            const savedCards = await getSavedCards();
            const formattedCards = savedCards.data.map((card: any) => ({
                id: card.payment_id,
                surname: card.alias,
                cardNumber: card.four_numbers,
            }));

            setCards(formattedCards);
        } catch (error) {
            console.error("Error fetching saved cards:", error);
        }
    }

    useEffect(() => {
        fetchSavedCards();
    }, []);

    interface CardData {
        id: number;
        surname: string;
        cardNumber: string;
    }

    interface Card {
        id: string;
        surname: string;
        cardNumber: string;
    }
    
    const deleteItem = async (id: string) => {
        setCards(currentCards => currentCards.filter(card => card.id !== id));
        await removeCard({payment_id: id});
    };

    const [selectedCard, setSelectedCard] = useState<CardData | null>(null);
    const handleSelectCard = (card: CardData) => {
        setSelectedCard(card);
      };

    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        fetchSavedCards();
    };
    return (
        <Container>
            <ButtonAddCard>
                <DataButton
                    onClick={() => {
                        handleOpenModal();
                    }}
                >
                    <FaPlus size={40} />
                    {t("add-a-credit-card")}
                </DataButton>
                <ModalDefault
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onClose={handleModalClose}
                >
                    {(closeModal: any) => (
                        <MySpaceBankRegister closeModal={closeModal} />
                    )}
                </ModalDefault>
            </ButtonAddCard>
            {cards.map((item: any) => (
                <Card
                    key={item.id}
                    surname={item.surname}
                    cardNumber={item.cardNumber}
                    deleteItem={() => deleteItem(item.id)}
                    isSelected={selectedCard?.id === item.id}
                    onSelect={() => handleSelectCard(item)}
                    isSelectionEnabled={false}
                />
            ))}
        </Container>
    );
}