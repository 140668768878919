import React, { useState } from 'react';
import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import { Container, ContentCard, GridContainer, LineHorizontal,Title } from "./style";
import Navbar from "../../components/navbar/navbar";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import CardFavorite from "../../components/card-favorite/card-favorito";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin: "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

const initialFavorites = [
  {
    id: 1,
    product: {
      image: "https://lh3.googleusercontent.com/24cU-k1KsjvRTmIsmq2nbGT1vHVVt9jw321YB3cP7jwtAaeQ6CD7vn-f-8SPPqI0q8EAQp83CkDZd92CAqVuH2sXjAmx_AV2dYkD9Dir5u8SSL_HO99X2M2T4ajNKtk3Vv5vElHW8Kh-UxLMVLVr3ck",
      name: "Fazenda Amanhecer",
    },
    price: 1000,
  },
  {
    id: 2,
    product: {
      image: "https://st3.depositphotos.com/1766887/13626/i/450/depositphotos_136268340-stock-photo-tropical-scenery-beautiful-palm-beach.jpg",
      name: "Fazenda Sol Poente",
    },
    price: 2500,
  },
];

export default function Favorites() {
  const [deletecards, setDeleteCards] = useState(initialFavorites);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLoading = false;

  const handleDetails = (item:any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;
    navigate(`/detailsProperty${queryString}`);
  };

  const deleteItem = (id:number) => {
    setDeleteCards(currentDeleteCards => currentDeleteCards.filter(card => card.id !== id));
  };

  return (
    <Container>
      <Navbar />
      <TitleComponent icon={CiHome} text={t("favorites")} />
      <GridContainer>
        {!isLoading && (
          <>
            {deletecards.length === 0 && <Title>{t("no-favorites")}.</Title>}
            {deletecards.map((item, index) => (
              <ContentCard key={index}>
                <CardFavorite
                  image={item.product.image}
                  title={item.product.name}
                  price={item.price}
                  is_favorite={true}
                  onClick={() => handleDetails(item)}
                  deleteItem={() => deleteItem(item.id)}
                />
                {index < deletecards.length - 1 && <LineHorizontal />}
              </ContentCard>
            ))}
          </>
        )}
        {isLoading && <span>Carregando...</span>}
      </GridContainer>
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
