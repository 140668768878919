import { useEffect, useState } from "react";
import { GoogleMap, Autocomplete, Marker, useLoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import configMaps from "./configMaps";
import { InputAdress } from "./style";

interface MapOptions {
    lat: number,
    lng: number,
}

const MapLocalize = ({
    getAddressComponent,
    setAddress,
    setLat,
    setLng,
    view,
}: {
    getAddressComponent: any;
    setAddress: any;
    setLat: any;
    setLng: any;
    view: boolean;
}) => {
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [selectedPlace, setSelectedPlace] = useState<MapOptions>({
        lat: 0,
        lng: 0,
    });
    const { t } = useTranslation();
    const [getAddress, setGetAddress] = useState("");

    useEffect(() => {
        setSelectedPlace({ lat: setLat, lng: setLng });
        setCenter({ lat: setLat, lng: setLng });
        setGetAddress(setAddress);
    }, []);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: configMaps.googleMapsApiKey,
        libraries: ["places", "geocoding"],
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    const onPlaceChanged = (place: any) => {
        if (place.geometry && place.geometry.location) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setCenter({ lat, lng });
            setSelectedPlace({ lat, lng });
            getAddressComponent(place);
        }
    };

    return (
        <>
            <Autocomplete
                onLoad={(autocomplete) => {
                    autocomplete.addListener("place_changed", () => {
                        onPlaceChanged(autocomplete.getPlace());
                    });
                }}
            >
                <InputAdress
                    type="text"
                    placeholder={t("title-address")}
                    defaultValue={getAddress}
                    required
                />
            </Autocomplete>
            {view && (
                <GoogleMap
                    mapContainerStyle={{ height: "400px", width: "100%" }}
                    center={center}
                    zoom={17}
                >
                    {selectedPlace && (
                        <Marker
                            position={{
                                lat: selectedPlace.lat,
                                lng: selectedPlace.lng,
                            }}
                        />
                    )}
                </GoogleMap>
            )}
        </>
    );
};

export default MapLocalize;
