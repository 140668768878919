import React, { useEffect, useState } from 'react';
import {
  Container,
  ButtonAddCard,
  DataButton,
  ButtonBuy,
  CreditCard,
  Line,
  SurnamemeCard,
  NumberCard

} from "./style-credit-card";
import Breadcrumb from '../../../../../components/breadcrumb/breadcrumb';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../../../../components/navbar/navbar';
import { FaPlus } from 'react-icons/fa6';
import Card from "../../../../../components/credit-card/card";
import Footer from '../../../../../components/footer/footer';
import { useServiceShoppingCart } from '../../../../../hooks/serviceShoppingCart';
import ModalDefault from '../../../../../components/modalDefault/ModalDefault';
import MySpaceBankRegister from '../../../../my-space/buy/my-space-bank-card';
import { ToastContainer, toast } from 'react-toastify';

interface Cardc {
    id: string;
    surname: string;
    cardNumber: string;
}

export default function CreditCardPayment() {
  const { t } = useTranslation();
  interface CardData {
    id: string;
    surname: string;
    cardNumber: string;
  }
  const [selectedCard, setSelectedCard] = useState<CardData | null>(null);
  const { getSavedCards, paymentCreate, removeCard } = useServiceShoppingCart();
  const [cards, setCards] = useState<Cardc[]>([]);
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonWaiting, setButtonWaiting] = useState(t("Payment"));

  const location = useLocation();
  const responseData = location.state?.response;

  async function fetchSavedCards() {
      try {
          const savedCards = await getSavedCards();
          const formattedCards = savedCards.data.map((card: any) => ({
              id: card.payment_id,
              surname: card.alias,
              cardNumber: card.four_numbers,
          }));

          setCards(formattedCards);
      } catch (error) {
          console.error("Error fetching saved cards:", error);
      }
  }

  useEffect(() => {
      fetchSavedCards();
  }, []);

  const deleteItem = async (id: string) => {
    setCards(currentCards => currentCards.filter(card => card.id !== id));
    await removeCard({ payment_id: id });
  };

  const handleSelectCard = (card: CardData) => {
    setSelectedCard(card);
    setButtonDisabled(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
      setShowModal(true);
  };

  const handleBuy = async () => {
    const data = {
        amount: responseData?.amount,
        payment_method: "card",
        payment_id: selectedCard?.id,
    };

    setButtonWaiting(`${t('waiting')}...`);

    await paymentCreate(data)
        .then((response) => {
            if (response.data.payment_status === "succeeded") {
                navigate("/PaySuccess");
            } else {
                navigate("/PayError");
            }
        })
        .catch((error) => {
            setButtonWaiting(t("Payment"));
            if (
                error.response &&
                error.response.data
            ) {
                const errorMessage = error.response.data.message;

                toast.error(t(errorMessage), {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        });
  }

  const handleModalClose = () => {
      fetchSavedCards();
  };

  return (
      <>
          <Navbar />
          <Breadcrumb itens={[t("order"), t("payments"), t("credit-card")]} />
          <Container>
              <div className="cardPayment">
                  <ButtonAddCard>
                      <DataButton
                          onClick={() => {
                              handleOpenModal();
                          }}
                      >
                          <FaPlus size={40} />
                          {t("add-a-credit-card")}
                      </DataButton>
                      <ModalDefault
                          showModal={showModal}
                          setShowModal={setShowModal}
                          onClose={handleModalClose}
                      >
                          {(closeModal: any) => (
                              <MySpaceBankRegister closeModal={closeModal} />
                          )}
                      </ModalDefault>
                  </ButtonAddCard>
                  <div>
                      {cards.map((item: any) => (
                          <Card
                              key={item.id}
                              surname={item.surname}
                              cardNumber={item.cardNumber}
                              deleteItem={() => deleteItem(item.id)}
                              isSelected={selectedCard?.id === item.id}
                              onSelect={() => handleSelectCard(item)}
                              isSelectionEnabled={true}
                          />
                      ))}
                  </div>
              </div>
              <div className="cardPayment">
                  <CreditCard>
                      <Line />
                      <NumberCard>
                          N°:{" "}
                          {selectedCard?.cardNumber.replace(
                              /.*(\d{4})$/,
                              "**** **** **** $1"
                          ) || ""}
                      </NumberCard>
                      <SurnamemeCard>
                          {selectedCard?.surname || ""}
                      </SurnamemeCard>
                  </CreditCard>
                  <ButtonBuy
                      onClick={() => handleBuy()}
                      disabled={buttonDisabled}
                  >
                      {buttonWaiting}
                  </ButtonBuy>
              </div>
              <ToastContainer />
          </Container>
          <Footer />
      </>
  );
}