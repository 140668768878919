import {
  Container,
  Input,
  InputContainer,
  InputLabel,
  InputError,
  Button,
  Center,
} from './style';

import {
    Formik,
    Field,
    Form,
    FormikHelpers,
    ErrorMessage,
} from "formik";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useServiceUser } from "../../../hooks/serviceUser";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from 'react';
import { IUser } from '../../../models/User';

export default function MySpaceProfile() {
  const { t } = useTranslation();
  const { updateUser, getUser } = useServiceUser();
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
      const fetchUser = async () => {
          try {
              const userData = await getUser();
              setUser(userData);
          } catch (error) {
              console.error("Error fetching user:", error);
          }
      };

      fetchUser();
  }, []);

  if (!user) {
    return null;
  }

  const initialValues = {
    fullname: user?.fullname || "",
    email: user?.email || "",
    phone: user?.phone || "",
    document: user?.document || "",
    state_registration: user?.state_registration || "",
    password: "",
    new_password_confirm: "",
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    const data = {} as Parameters<typeof updateUser>[0];

    if (values.fullname.length > 0) {
      data.fullname = values.fullname;
    }

    if (values.phone.length > 0) {
      data.phone = values.phone;
    }

    if (values.state_registration.length > 0) {
      data.state_registration = values.state_registration;
    }

    if (values.password.length > 0) {
      data.password = values.password;
    }

    try {
      await updateUser(values);
      toast.success(t("successfully"), {
          position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error((error as Error).message, {
        position: 'top-right',
      });
    }
  };

  const formValidation = (values: typeof initialValues) => {
    if (values.password.length > 0) {
      if (values.password !== values.new_password_confirm) {
        return {
          new_password_confirm: t("password-not-equal"),
        };
      }

      // if (values.old_password.length === 0) {
      //   return {
      //     old_password: t("password-required"),
      //   };
      // }
    }

    if (values.fullname.length === 0) {
      return {
        fullname: t("field-required"),
      };
    }

    return {};
  };

  return (
      <Container>
          <ToastContainer />
          <Formik
              validate={formValidation}
              initialValues={initialValues}
              onSubmit={handleSubmit}
          >
              <Form>
                  <InputContainer>
                      <div>
                          <InputLabel>{t("name")}</InputLabel>
                          <Field
                              as={Input}
                              type="text"
                              name="fullname"
                          />
                          <InputError>
                              <ErrorMessage name="fullname" />
                          </InputError>

                          <InputLabel>{t("phone")}:</InputLabel>
                          <Field as={Input} type="text" name="phone" />
                          <InputError>
                              <ErrorMessage name="phone" />
                          </InputError>

                          <InputLabel>Cnpj/Cpf:</InputLabel>
                          <Field
                              as={Input}
                              type="text"
                              name="document"
                              disabled
                          />
                          <InputError>
                              <ErrorMessage name="document" />
                          </InputError>

                          <InputLabel hidden={user?.document_type === "cpf"}>
                              {t("state-registration")}:
                          </InputLabel>
                          <Field
                              as={Input}
                              type="text"
                              name="state_registration"
                              style={{
                                  display: `${
                                      user?.document_type === "cpf"
                                          ? "none"
                                          : "block-inline"
                                  }`,
                              }}
                          />
                          <InputError>
                              <ErrorMessage name="state_registration" />
                          </InputError>
                      </div>

                      <div>
                          <InputLabel>{t("email")}</InputLabel>
                          <Field
                              as={Input}
                              type="email"
                              name="email"
                              disabled
                          />
                          <InputError>
                              <ErrorMessage name="email" />
                          </InputError>

                          {/* <InputLabel>{t("old-password")}:</InputLabel>
                          <Field
                              as={Input}
                              type="password"
                              name="old_password"
                          /> */}
                          {/* <InputError>
                              <ErrorMessage name="old_password" />
                          </InputError> */}

                          <InputLabel>{t("new-password")}:</InputLabel>
                          <Field
                              as={Input}
                              type="password"
                              name="password"
                          />
                          <InputError>
                              <ErrorMessage name="new_password" />
                          </InputError>

                          <InputLabel>{t("confirm-new-password")}:</InputLabel>
                          <Field
                              as={Input}
                              type="password"
                              name="new_password_confirm"
                          />
                          <InputError>
                              <ErrorMessage name="new_password_confirm" />
                          </InputError>
                      </div>
                  </InputContainer>

                  <Center>
                      <Button type="submit">{t("update")}</Button>
                  </Center>
              </Form>
          </Formik>
      </Container>
  );
}
