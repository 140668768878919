import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { parseProperty, parsePropertyList, Property } from "../models/Property";
import { apiBase } from "../utils/server/api";
import { Cart, parseCart } from "../models/Cart";

export type PropertyCreate = {
    name: string;
    deed_number: string;
    acre: string;
    cib_nirf_number: string;
    cost: string;
    description: string;
    vegetation: string;
    address: string;
    file_deed_number: string;
    file_cib: string;
    file_proof_of_residence: string;
    file_images: string;
};

const createOrder =
    (config: ReturnType<typeof useAuth>["axiosConfig"], propertyId: number) =>
    async () => {
        try {
            const response = await axios.post(
                `${apiBase}/shopping`,
                { propertyId: propertyId },
                config()
            );
            return parseCart(response.data as Cart);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

const getCart =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async () => {
        return await axios
            .get(`${apiBase}/shopping`, config())
            .then((response: any) => {
                return parseCart(response.data as Cart);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const removeItem =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async (itemId: number) => {
        return await axios
            .post(
                `${apiBase}/shopping/removeItem`,
                { itemId: itemId },
                config()
            )
            .then((response: any) => {
                return parseCart(response.data as Cart);
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const paymentCreate =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: any) => {
        return await axios
            .post(`${apiBase}/payment/create`, data, config())
            .then((response: any) => {
                return response;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const paymentCard =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: {token: string, amount: number, email: string, name: string, payment_method: string}) => {
        return await axios
            .post(`${apiBase}/payment/create`, data, config())
            .then((response: any) => {
                return response;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const saveCreditCardToken =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: { token: string; four_numbers: any; validate: string; alias: string }) => {
        return await axios
            .post(`${apiBase}/payment/save-credit-card`, data, config())
            .then((response: any) => {
                return response;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getSavedCards =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async () => {
        return await axios
            .get(`${apiBase}/payment/get-saved-cards`, config())
            .then((response: any) => {
                return response;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const removeCard =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: {
        payment_id: string;
    }) => {
        return await axios
            .post(`${apiBase}/payment/remove-card`, data, config())
            .then((response: any) => {
                return response;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getFinishedOrders =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/payment/get-finished-orders`, config())
            .then((response: any) => {
                return response;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const alterQuantity =
    (config: ReturnType<typeof useAuth>["axiosConfig"]) =>
    async (data: { propertyId: number; quantity: number }) => {
        return await axios
            .post(`${apiBase}/shopping/alterQuantity`, data, config())
            .then((response: any) => {
                return response;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };


export function useServiceShoppingCart(id?: any, itemId?: any) {
    const { axiosConfig } = useAuth();

    return {
        createOrder: createOrder(axiosConfig, id),
        getCart: getCart(axiosConfig),
        removeItem: removeItem(axiosConfig),
        paymentCreate: paymentCreate(axiosConfig),
        paymentCard: paymentCard(axiosConfig),
        saveCreditCardToken: saveCreditCardToken(axiosConfig),
        getSavedCards: getSavedCards(axiosConfig),
        removeCard: removeCard(axiosConfig),
        getFinishedOrders: getFinishedOrders(axiosConfig),
        alterQuantity: alterQuantity(axiosConfig),
    };
}
