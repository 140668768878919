import styled from "styled-components";

export const CardContainer = styled.div`
display: flex; 
  justify-content: space-between; /* Distribui o espaço entre os conteúdos */
  width: 500px; 
  height: 136px; /* altura maxima */
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 25px;
  padding-top: 10px;
  margin-bottom: 40px;
`;


export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 22px;
  font-family: "poppins";
`;

export const CardContentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 22px;
  font-family: "poppins";
  margin-right: 10px;
`;

export const ButtonTrash = styled.div`
color: red;
cursor: pointer;
`;