import styled from "styled-components";

export const Container = styled.div`
  width: 100%; /* 100% da largura do contêiner pai */
  border: 1px solid #d1c7c7;
  border-radius: 15px;
  position: relative;
  width: 100%; 
  height: auto; /* altura maxima */
  display: flex; /* Adicione display flex para alinhar os itens */
  flex-direction: column; /* Ajuste para uma direção de coluna */
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const Content = styled.div `
  display: flex;
  flex-direction: row;
`;
export const Date = styled.div `
display: flex;
width: 10%;
align-items: center;
`;

export const Date2 = styled.div `
  width: 90%;
`;

export const Title = styled.h1`
  font-family: "poppins";
  color: #000;
  font-size: 22px;
  text-align: center;
  white-space: normal;
  margin-top: 10px;
`;

export const Text = styled.p`
  font-family: "poppins";
  color: #50700F;
  font-size: 18px;
  text-align: end;
  margin-right: 40px;
  margin-top: 5px;
`;
