import React, { useState } from "react";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../../utils/stripeConfig";

import {
    Container,
    ContainerForms,
    LabelCard,
    ButtonSubmit,
    FieldInput,
    Inputs,
} from "./style";
import "./style.css";

import { useTranslation } from "react-i18next";
import { useServiceShoppingCart } from "../../../hooks/serviceShoppingCart";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "20px",
            "::placeholder": {
                color: "#aab7c4",
            },
            borderColor: "1px solid #000",
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true,
};


const CheckoutForm = ({ closeModal }: { closeModal: any }) => {
    const { t } = useTranslation();

    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState("");
    const [alias, setAlias] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { saveCreditCardToken } = useServiceShoppingCart();
    const [buttonText, setButtonText] = useState(t("save-card"));
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setErrorMessage("Card details not found");
            return;
        }

        setButtonText(t("waiting"));
        setButtonDisabled(true);

        const { error, token } = await stripe.createToken(cardElement, {
            name,
        });

        if (error) {
            setErrorMessage(error.message as string);
            return;
        }

        if (token && token.card) {
            await saveCreditCardToken({
                token: token.id,
                four_numbers: token.card.last4,
                validate: `${token.card.exp_month}/${token.card.exp_year}`,
                alias: alias,
            })
                .then((response) => {
                    closeModal();
                })
                .catch((error: any) => {
                    setErrorMessage(error.response.data.error.message);
                    setButtonText(t("save-card"));
                    setButtonDisabled(false);
                });
        }
    };

    return (
        <Container>
            <ContainerForms>
                <form onSubmit={handleSubmit}>
                    <FieldInput>
                        <LabelCard>{t("Cardholder-Name")}:</LabelCard>
                        <Inputs
                            type="text"
                            name="cardholderName"
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </FieldInput>
                    <FieldInput>
                        <LabelCard>{t("alias-card")}:</LabelCard>
                        <Inputs
                            type="text"
                            name="apelido"
                            onChange={(e) => setAlias(e.target.value)}
                            required
                        />
                    </FieldInput>

                    <div className="card-element-wrapper">
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </div>

                    <ButtonSubmit type="submit" disabled={buttonDisabled}>
                        {buttonText}
                    </ButtonSubmit>
                </form>
            </ContainerForms>
        </Container>
    );
};

const MySpaceBankRegister = ({ closeModal }:{closeModal?:any}) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm closeModal={closeModal} />
    </Elements>
);

export default MySpaceBankRegister ;