import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  width: 100%;
`;

export const ContainerCard = styled.div`
    margin: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${colors.gray};
    border-radius: 10px;
    max-height: 300px;
    max-width: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
`;

export const ContentCard = styled.div`
  width: 85%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  p {
    text-overflow: ellipsis;
  }
`;

export const CardTitle = styled.h3`
    font-size: 18px;
    color: ${colors.text};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colunas com largura igual */
  margin-left: 30px;
  min-height: 56.8vh;
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

export const Title = styled.h1`
    color: ${colors.black};
    font-size: 30px;
    padding: 0;
    margin: 0;
`;

export const Button = styled.button`
    background-color: ${colors.secondary};
    color: white;
    border-radius: 10px;
    padding: 10px;
    padding-left: 100px;
    padding-right: 100px;
`;

export const CardImage = styled.div<{
    src: string;
}>`
    width: 218px;
    height: 144px;
    border-radius: 10px;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
`;