import React, { useRef, useState } from 'react';
import {
  Container,
  ContentQrcode,
  ButtonBack,
  InputContainer,
  InputField,
  CopyButton,
} from "./style-pix";
import { IoMdArrowRoundForward } from "react-icons/io";

import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Navbar from '../../../../../components/navbar/navbar';
import Breadcrumb from '../../../../../components/breadcrumb/breadcrumb';
import Footer from '../../../../../components/footer/footer';

export default function Pix() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pixKey, setPixKey] = useState('00020126580014BR.GOV.BCB.PIX01369604567c-c083-4e82-945689889-24234324234...');  
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      alert('Texto copiado!');
    }
  };

  return (
    <>
      <Navbar />
      <Breadcrumb itens={[t("order"), t("payments"), t("pix")]} />
      <Container>
        <ContentQrcode>
          <div style={{ width: "256px", height: "256px", backgroundColor: "gray" }}></div>
          <div style={{ width: "818px", height: "270px", marginTop: "15px" }}>
            <p>Passo a passo para escanear QR Code Pix<br />
              1. Abra no seu celular ou tablet o aplicativo do banco pelo qual deseja fazer o pagamento;<br />
              2. Acesse as opções referentes ao Pix;<br />
              3. Selecione a opção “Pagar”;<br />
              4. Selecione a opção “Ler QR Code”. A seguir, o aplicativo vai acessar a câmera do seu aparelho;<br />
              5. Aponte a câmera para o QR Code;<br />
              6. Os dados da transação, como valor e beneficiário serão exibidos na sua tela, confirme se estão todos corretos;<br />
              7. Selecione a opção “Pagar”;</p>
            <InputContainer>
              <CopyButton onClick={handleCopy}>{t("pix")}:</CopyButton>
              <InputField type="text" ref={inputRef} value={pixKey} readOnly />
            </InputContainer>
          </div>
        </ContentQrcode>
        <div>
          <ButtonBack onClick={()=>{navigate("/order")}}>{t("my-orders")} <IoMdArrowRoundForward size={20} /></ButtonBack>
        </div>
      </Container>
      <Footer />
    </>
  );
}
