import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ContainerCard = styled.div`
  margin: 30px;
  overflow-x: auto; /* Adiciona um scroll horizontal */
  white-space: nowrap; /* Impede que os itens quebrem para uma nova linha */
`;

export const ContainerTitle = styled.div`
  display: flex;
  margin: 30px;
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 22px;
`;

export const CardWrapper = styled.div`
  display: inline-block; /* Faz os itens ficarem na mesma linha */
  margin-right: 16px; /* Adiciona um espaçamento entre os itens */
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colunas com largura igual */
`;
