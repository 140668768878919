import styled from "styled-components";

export const Container = styled.div`
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export const Content = styled.div`
    flex-direction: row;
    display: flex;
    margin-top: 0;

    @media only screen and (max-width: 768px) {
        width: 80%;
        flex-direction: column;
    }

    @media only screen and (max-width: 914px) {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`;