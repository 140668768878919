import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./json/en.json";
import ptTranslations from "./json/pt.json";

const resources = {
  en: { translation: enTranslations },
  pt: { translation: ptTranslations },
};

const getLanguage = localStorage.getItem("language");

async function initializeI18n() {
  const language = await Promise.resolve(getLanguage);

  i18n.use(initReactI18next).init({
    resources,
    lng: language || "pt",
    fallbackLng: language || "pt",
    interpolation: {
      escapeValue: false,
    },
  });
}

initializeI18n();

export default i18n;
